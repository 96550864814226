import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Divider, Form, Input} from "antd";
import ActiveBalances from "../Trsansactions/ActiveBalances";
import {userDataSelector} from "../../redux_ver2/selector/user.selector";
import {redeemActivateAction, resendActivationMailAction} from "../../redux_ver2/actions/auth.actions";

const UserInfo = () => {

    const user = useSelector(userDataSelector);
    const dispatch = useDispatch();

    const [redeem, setRedeem] = useState("");
    const [copySuccess, setCopySuccess] = useState(''); // State to handle copy success message

    const handleRedeemChange = (e) => setRedeem(e.target.value);

    const onFinish = () => dispatch(redeemActivateAction(user?.id, user?.username, redeem));

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopySuccess('Copied to clipboard!');

            // Reset the message after 1 second
            setTimeout(() => {
                setCopySuccess('');
            }, 1000);
        }).catch(err => {
            setCopySuccess('Failed to copy!');
        });
    };

    return (
        <div>
            <div style={{
                boxShadow: "0px 5px 50px rgba(0, 0, 0, 0.5)",
                padding: "8px",
                borderRadius: "10px",
                backgroundColor: "rgba(30, 29, 48, 0.1)",
                marginBottom: "15px"
            }}>
                <h4 style={{fontSize: "19px", marginBottom: "3.5px",}}>Username</h4>
                <p>{user?.username}</p>
            </div>

            <div style={{
                boxShadow: "0px 5px 50px rgba(0, 0, 0, 0.5)",
                padding: "8px",
                borderRadius: "10px",
                backgroundColor: "rgba(30, 29, 48, 0.1)",
                marginBottom: "15px"
            }}>
                <h4 style={{fontSize: "19px", marginBottom: "3.5px",}}>Email</h4>
                <p>
                    {user.email} {user.isEmailConfirmed && <span style={{color: "#32cd32"}}> Confirmed</span>}
                    {
                        !user.isEmailConfirmed &&
                        <>
                            <p style={{margin: "10px 0"}}>Your email is not verified</p>
                            <Button
                                style={{marginBottom: "10px"}}
                                type={"primary"}
                                onClick={() => dispatch(resendActivationMailAction(user?.email))}
                            >Resend activation code</Button>
                        </>
                    }
                </p>
            </div>

            <div style={{
                boxShadow: "0px 5px 50px rgba(0, 0, 0, 0.5)",
                padding: "8px",
                borderRadius: "10px",
                backgroundColor: "rgba(30, 29, 48, 0.1)",
                marginBottom: "10px"
            }}>
                <h4 style={{fontSize: "19px", marginBottom: "3.5px",}}>Date joined</h4>
                <p>{user?.joinedDate}</p>
            </div>

            <Divider style={{border: "1px solid grey"}}/>

            {/* <div style={{
                boxShadow: "0px 5px 50px rgba(0, 0, 0, 0.5)",
                padding: "8px",
                borderRadius: "10px",
                backgroundColor: "rgba(30, 29, 48, 0.1)"
            }}>
                <h4 style={{fontSize: "19px", marginBottom: "3.5px",}}>Stats</h4>
                <p> Coming Soon</p> */}
                {/*<p>*/}
                {/*    Total bets: {user?.totalBetsCount}*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*    Total wagered: {user?.totalGambledUsd}$*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*    Profit: {user?.totalProfitUsd}$*/}
                {/*</p>*/}
            {/* </div> stats currently not working */}

                <br />

            <div style={{
                boxShadow: "0px 5px 50px rgba(0, 0, 0, 0.5)",
                padding: "8px",
                borderRadius: "10px",
                backgroundColor: "rgba(30, 29, 48, 0.1)",
                marginBottom: "15px",
                marginTop: "-15px"
            }}>{/* remove marginTop when stats released*/}
                <h4 style={{fontSize: "19px", marginBottom: "3.5px",}}>Refer a friend, Get $5</h4>
                {/* <p style={{padding: "5px 0"}}>When your friend starts playing on SpinSways, you'll get $5.</p> */}
                <p style={{padding: "5px 0"}}>For more information on this, contact us on <a href='https://twitter.com/SpinSways' target='_blank' style={{color: "#6565c6", textDecoration: "none",}}>Twitter</a>.</p>
                
                <p
                style={{
                    marginTop: "10px"
                }}
                >
                {user.redeem.redeemCode}
                <button
                    onClick={() => copyToClipboard(user.redeem.redeemCode)}
                    className='copy-btn'
                    style={{ 
                        marginLeft: '10px',
                    }}
                >
                    Click to Copy
                </button>
                <span style={{ marginLeft: '10px' }}>{copySuccess}</span>
            </p>
            </div>

            <div style={{
                boxShadow: "0px 5px 50px rgba(0, 0, 0, 0.5)",
                padding: "8px",
                borderRadius: "10px",
                backgroundColor: "rgba(30, 29, 48, 0.1)",
                marginBottom: "15px"
            }}>
                <h4 style={{fontSize: "19px", marginBottom: "3.5px",}}>Your referral code</h4>
                {
                user.redeem.isRedeemActivated ?
                    <p>Referral code activated. Invited by: {user.redeem.inviter.username}</p> :
                    <>
                        <p style={{margin: "10px 0 5px 0"}}>You did not activate referral code yet</p>
                        <Form onFinish={onFinish}>
                            <Form.Item
                                name={"redeem"}
                            >
                                <Input
                                    style={{marginBottom: "-10px", fontSize: "15px"}}
                                    placeholder={"Enter your inviter's referral code here"}
                                    value={redeem}
                                    onChange={handleRedeemChange}
                                />
                            </Form.Item>

                            <Button
                                type={"primary"}
                                htmlType={"submit"}
                            >Activate</Button>
                        </Form>
                    </>
            }
            </div>

            <Divider style={{border: "1px solid grey"}}/>

            <div className="dropdown-transactions"
                style={{
                    backgroundColor: "#191828",
                    borderRadius: "10px",
                    boxShadow: "0px 5px 50px rgba(0, 0, 0, 0.5)",
                }}
            >

                <h3 style={{marginTop: "20px", padding: "8px"}}>Active Balances</h3>

                <ActiveBalances/>
            </div>
        </div>
    );
};

export default UserInfo;
