import React from 'react';
import superRoundIMG from "../../assets/images/superRoundIMG.jpg";

const SuperRounds = () => {

    return (
        <div className='super-round-modal'>

            <div style={{height: "50vh"}}>
                <h4>Coming Very Soon!</h4><br />
                <p>Follow our <a href='https://twitter.com/SpinSways' target='_blank' className='coming-very-soon-text'>Twitter</a> for all updates on @SpinSways!</p>
            </div>

        </div>
    );
};

export default SuperRounds;