export const TOGGLE_PRELOADER = "TOGGLE_PRELOADER";
export const TOGGLE_LOADING = "TOGGLE_LOADING";

export const loadingStart = () => async dispatch => {
    dispatch({
        type: TOGGLE_PRELOADER,
        payload: {
            preloader: true
        }
    });
}

export const loadingFinish = () => async dispatch => {
    dispatch({
        type: TOGGLE_PRELOADER,
        payload: {
            preloader: false
        }
    });
}