import React from 'react';

const Support = () => {
    return (
        <div>
            <h3>We're here to help!</h3><br />
            <p className="common-text">Whether you have a question, need assistance with a transaction, or require technical support, we are ready to provide you with efficient service.</p><br /><br />

            <p>FAQ Section: <span className="common-text">Check out our <a className="mobile-telegram-support" href="https://spinsways.gitbook.io/about/faq" target="_blank">FAQ section</a> for quick answers to common questions.</span></p><br />

            <p>Twitter: <span className="common-text">Message us on <a className="mobile-telegram-support" href="https://twitter.com/SpinSways" target="_blank">Twitter</a>, and we'll respond as soon as possible.</span></p><br />

            <p>Telegram: <span className="common-text">You can also reach out to us on <a className="mobile-telegram-support" href="https://t.me/SpinSwayscomSupport" target="_blank">Telegram</a>.</span></p><br />

            <p>Leave Feedback: <span className="common-text">Drop us an email at support@spinsways.com (note: we will not be able to reply with this email)</span></p><br /><br />

            <p className="common-text">Your gaming experience is our top priority. Reach out to us anytime. We're here to ensure that your time is enjoyable and hassle-free!</p>
        </div>
    );
};

export default Support;
