import React from 'react';
import ModalPage from "../../pages/ModalPage";
import UserInfo from "./UserInfo";

const UserInfoWrapper = () => {
    return (
        <ModalPage
            component={<UserInfo/>}
            title={"Your Account Information"}
            titleClassName={"wrapper-text-phones"}
        />
    );
};

export default UserInfoWrapper;