import React from 'react';
import LuckyWheel from "./LuckyWheel";

const LuckyWheelContainer = () => {
    return (
        <div className='lucky-wheel-modal'>

            <div style={{height: "50vh"}}>
                <h4>Coming Very Soon!</h4><br />
                <p>Follow our <a href='https://twitter.com/SpinSways' target='_blank' className='coming-very-soon-text'>Twitter</a> for all updates on @SpinSways!</p>
            </div>

            {/* <div className='lucky-wheel-main-container'>
                <LuckyWheel />
                <div className='luckywheel-main-text'>
                    <p className="spins-left">Spins Left: <span>0</span></p><br />
                    <p>
                        <span id="prize1">• 1 ETH direct transfer (1%)</span><br />
                        <span id="prize2">• 25% Rakeback for 24h (10%)</span><br />
                        <span id="prize3">• 50% Bonus within 24h (14%)</span><br />
                        <span id="prize4">• 50 USDT direct transfer (15%)</span><br />
                        <span id="prize5">• 20$ Credit (15%)</span><br />
                        <span id="prize6">• 10$ Credit (15%)</span><br />
                        <span id="prize7">• 5$ Credit (20%)</span><br />
                        <span id="prize8">• Re-spin (10%)</span><br />
                        <br />
                    </p>
                </div>
            </div> */}
        </div>
    );
};

export default LuckyWheelContainer;