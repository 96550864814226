import socket from "../../socket";


export const bet = (
    username,
    amount,
    crypto,
    profit,
    cashOut,
    fixedCashOut,
    timeZone
) => {
    socket.emit("game:join", {
        username,
        amount,
        crypto,
        profit,
        cashOut,
        fixedCashOut,
        timeZone
    });
};

export const betNext = (
    username,
    amount,
    crypto,
    profit,
    cashOut,
    fixedCashOut,
    timeZone
) => {
    socket.emit("game:join_next", {
        username,
        amount,
        crypto,
        profit,
        cashOut,
        fixedCashOut,
        timeZone
    });
}


export const cancel = (
    username
) => {
    socket.emit("game:cancel", username);
}

export const cashOut = (
    username,
    multiplier,
    timeZone
) => {
    socket.emit("game:cash_out", {
        username,
        multiplier,
        timeZone
    });
};