import React from 'react';

const LiveBettors = (props) => {

    // get currentBettors array from props
    const {liveBettors} = props;

    // TODO

    return (
        <>
            <table className={"live-bets-table"}>
                    <thead className='thead-baby'>
                        <tr className={"live-bets-row"} style={{overflow: "hidden"}}>
                        {/*<tr className={"table-header"}>*/}
                            <th className={"live-bets-text"}>Name</th>
                            <th className={"live-bets-text"}>Cashout</th>
                            <th className={"live-bets-text"}>Amount</th>
                            <th className={"live-bets-text"}>Currency</th>
                            <th className={"live-bets-text"}>Profit</th>
                        </tr>
                    </thead>

                {/* <div className="scrollable-content-live"> */}
                    <tbody>
                        {liveBettors?.map((item, index) => (
                            <tr className={"live-bets-row live-bets-player"} key={index}>
                                <td className={"live-bets-cell"}>{item?.username}</td>
                                <td className={"live-bets-cell"}>{item?.cashOut}x</td>
                                <td className={"live-bets-cell"}>{item?.amount}</td>
                                <td className={"live-bets-cell"}>{item?.crypto}</td>
                                <td className={"live-bets-cell"}>{item?.profit}</td>
                            </tr>
                        ))}
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                        <tr className={"live-bets-row live-bets-player"}>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                            <td className={"live-bets-cell"}>test</td>
                        </tr>
                    </tbody>
                {/* </div> */}
            </table>
        </>
    );
};

export default LiveBettors;