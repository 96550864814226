import {LOG_IN, LOGOUT, REFRESH, REGISTER} from "../actions/auth.actions";
import {RESET_PASSWORD_BY_LINK} from "../actions/security.actions";
import {BETS_HISTORY_UPDATE} from "../actions/bet-history.actions";

const initialState = {
    betHistory: []
}

export const betHistoryReducer = (state = initialState, action) => {
    switch (action.type) {

        case LOG_IN:
            return {
                betHistory: action.payload.bets.history
            }

        case REGISTER:
            return {
                betHistory: action.payload.bets.history
            }

        case LOGOUT:
            return {
                betHistory: []
            }

        case REFRESH:
            return {
                betHistory: action.payload.bets.history
            }

        case RESET_PASSWORD_BY_LINK:
            return {
                betHistory: []
            }

        case BETS_HISTORY_UPDATE:
            return {
                betHistory: action.payload.betsHistory
            }

        default:
            return state;

    }
}