import $api, {API_URL} from "../api";
import axios from "axios";
import {getRefreshTokenExpireValidate} from "../utils/tokenUtils";

export const login = async (login, password) => {
    return await $api.post("/login", {
        login,
        password
    });
};

export const registration = async (username, email, password, redeem) => {
    return await $api.post("/registration", {
        username,
        password,
        email,
        redeem
    });
};

export const logout = async () => {
    return await $api.post("/logout", {
        refreshToken: JSON.parse(localStorage.getItem("rToken")).refreshToken
    });
};

export const resendActivateMail = async (email) => {
    return await $api.post("/resend-activate-email", {
        email
    });
};

export const refresh = async () => {
    return await axios.post(`${API_URL}/refresh`, {
        refreshToken: getRefreshTokenExpireValidate()
    });
};

export const redeemActivate = async (userId, username, code) => {
    return await axios.post(`${API_URL}/activate-redeem`, {
        userId,
        username,
        redeem: code
    })
}