import React, {useEffect, useRef, useState} from 'react';
import 'chart.js/auto';
import {Chart, Line} from 'react-chartjs-2';
import 'chartjs-adapter-moment'
import socket from "../../socket";

const GameGraph = () => {

    const [isGameRunning, setIsGameRunning] = useState(false);
    const [liveMultiplier, setLiveMultiplier] = useState(1.00);
    const [timeElapsed, setTimeElapsed] = useState(0);

    const [chartData, setChartData] = useState({datasets: []});
    const [chartOptions, setChartOptions] = useState({});

    const multiplierCount = useRef([])
    const timeCount_xaxis = useRef([])
    const realCounter_yaxis = useRef(5);

    useEffect(() => {
        if (!isGameRunning) {
            multiplierCount.current = [];
            timeCount_xaxis.current = [];
            updateChart();
        }
    }, [isGameRunning]);

    useEffect(() => {
        socket.on("game:time_elapsed", elapsed => setTimeElapsed(elapsed));
        socket.on("game:state", state => setIsGameRunning(state));
        socket.on("game:multiplier", multiplier => setLiveMultiplier(multiplier));
    }, []);

    useEffect(() => {
        if (isGameRunning) {
            if (multiplierCount.current.length < 1) {
                multiplierCount.current = multiplierCount.current.concat([1]);
                timeCount_xaxis.current = timeCount_xaxis.current.concat([0]);
            }

            if (realCounter_yaxis.current % 5 === 0) {
                multiplierCount.current = multiplierCount.current.concat([liveMultiplier]);
                timeCount_xaxis.current = timeCount_xaxis.current.concat([timeElapsed]);
            }
            realCounter_yaxis.current += 1;
            updateChart();
        }
    }, [liveMultiplier]);

    const updateChart = () => {
        setChartOptions({
                events: [],
                maintainAspectRatio: false,
                elements: {
                    line: {
                        tension: 0.2
                    }
                },
                scales: {
                    yAxes: {
                        type: 'linear',

                        title: {
                            display: false,
                            text: 'value'
                        },
                        min: 1,
                        max: ((liveMultiplier < 2 || !isGameRunning) ? 2 : liveMultiplier),
                        ticks: {
                            color: "rgba(255, 255, 255, 1)",
                            maxTicksLimit: 5,
                            callback: function (value, index, values) {
                                if (value % 1 === 0) return value;
                            }
                        },
                        grid: {
                            display: true,
                            // color: "rgba(255,255,255,0.0)"
                        },
                    },
                    xAxes: {
                        type: 'linear',
                        title: {
                            display: false,
                            text: 'value'
                        },
                        max: (timeElapsed < 2 || !isGameRunning ? (2) : (timeElapsed)),
                        ticks: {
                            color: "rgba(255, 255, 255, 1)",

                            maxTicksLimit: 5,
                            callback: function (value, index, values) {
                                if (timeElapsed < 10) {
                                    if (value % 1 === 0) return value
                                } else {
                                    if (value % 5 === 0) return value
                                }
                            }
                        },
                        grid: {
                            display: true,
                            color: "rgba(255,255,255,0)"
                        },
                    },
                },
                plugins: {
                    legend: {display: false},
                },
                animation: {
                    x: {
                        type: 'number',
                        easing: 'linear',
                        duration: 0,
                        from: 5,
                        delay: 0
                    },
                    y: {
                        type: 'number',
                        easing: 'linear',
                        duration: 0,
                        from: 5,
                        delay: 0
                    },
                    // loop: true,
                },
            }
        );

        setChartData({
            labels: timeCount_xaxis.current,
            datasets: [
                {
                    data: multiplierCount.current,
                    borderColor: "rgba(255, 255, 255, 1)",
                    pointRadius: 0,
                    // borderDash: [],
                    // lineTension: 0.5,
                    borderWidth: 2,
                    fill: false
                },
            ],

        });
    };

    return (
        <>
            {
                chartData ?
                    <div style={{height: '100%', width: '100%'}}>
                        <Chart
                            type='line' data={chartData}
                            options={chartOptions}
                        />
                    </div> : ("")
            }
        </>
    );
};

export default GameGraph;