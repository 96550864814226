import React from 'react';
import ModalPage from "../../pages/ModalPage";
import MobileLeaderboard from "./MobileLeaderboard";

const MobileLeaderboardWrapper = () => {
    return (
        <ModalPage
            component={<MobileLeaderboard/>}
            title={"Leaders"}
        />
    );
};

export default MobileLeaderboardWrapper;