export const saveRefreshWithExpiredDate = (token) => {

    const currentDateTime = new Date();
    // token time to live
    const tokenTTL = 5 * 24 * 60 * 60 * 1000;

    const refreshTokenData = {
        refreshToken: token,
        expires: currentDateTime.getTime() + tokenTTL
    };

    localStorage.setItem("rToken", JSON.stringify(refreshTokenData));

}

export const getRefreshTokenExpireValidate = () => {
    let data = localStorage.getItem("rToken");

    if (!data) return null;

    data = JSON.parse(data);
    const currentDateTime = new Date();

    if (currentDateTime.getTime() > data.expires) {
        // Если истек, удаляем элемент и возвращаем null
        localStorage.removeItem("rToken");
        return null;
    }

    return data.refreshToken;
}