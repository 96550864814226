import $api from "../../api";
import {TOGGLE_LOADING} from "./loading.actions";
import {FEEDBACK} from "./feedback.actions";

export const BALANCES_UPDATE = "BALANCES_UPDATE";
export const WITHDRAW_CANCEL_SOCKET = "WITHDRAW_CANCEL_SOCKET";
export const DEPOSIT_V2 = "DEPOSIT_V2";
export const QR_REDIRECT = "QR_REDIRECT";
export const WITHDRAW_REQUEST = "WITHDRAW_REQUEST";


export const balancesUpdateAction = (data) => async dispatch => {

    dispatch({
        type: BALANCES_UPDATE,
        payload: {
            balances: data
        },
    });

};

export const withdrawCancelAction = data => async dispatch => {
    dispatch({
        type: WITHDRAW_CANCEL_SOCKET,
        payload: {
            withdraws: data.withdraws
        }
    });


}

export const depositAction = (crypto, sub_partner_id, amount, userId) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: true
            }
        });

        // message, wallet dto
        const paymentResponse = await $api.post("/deposit-v2", {
            crypto,
            sub_partner_id,
            amount,
            userId
        });

        dispatch({
            type: FEEDBACK,
            payload: {
                isError: false,
                message: paymentResponse?.data?.message?.message
            }
        });

        dispatch({
            type: DEPOSIT_V2,
            payload: {
                deposits: paymentResponse?.data?.wallet?.deposits,
                waiting: paymentResponse?.data?.wallet?.waiting
            }
        });

        dispatch({
            type: QR_REDIRECT,
            payload: {
                redirect: true,
                link: paymentResponse?.data?.wallet?.deposits[0].address
            }
        });

    } catch (error) {
        dispatch({
            type: FEEDBACK,
            payload: {
                isError: true,
                message: error?.response?.data?.message
            }
        })
    } finally {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: false
            }
        })
    }
}

export const resetRedirectAction = () => async dispatch => {
    dispatch({
        type: QR_REDIRECT,
        payload: {
            redirect: false,
            link: ""
        }
    });
};