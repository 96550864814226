import React from 'react';
import {useNavigate} from "react-router-dom";
import {PATH} from "../../router/PATH";

const Fairness = () => {

    const navigate = useNavigate();

    return (
        <div>
            <h2>Solving Trust Issues</h2><br />
            <div>
                <strong>1. Provable Fairness</strong>
                <p style={{fontSize: "15px"}}>Our crash game is governed by a provably fair algorithm, ensuring each outcome is random and impartial. Verify the fairness of each round using our <a onClick={() => {
                    navigate(PATH.FAIRNESS.FAIRNESS_VERIFICATION)
                }} style={{color: "#8f87ff", cursor: "pointer"}}>Verification Tool</a>.</p>
            </div><br />
            <div>
                <strong>2. House Edge</strong>
                <p style={{fontSize: "15px"}}>We maintain a low house edge of just 0.8%, offering better odds and a fair gaming experience.</p>
            </div><br />
            <div>
                <strong>3. Responsible Gaming</strong>
                <p style={{fontSize: "15px"}}>For information on responsible gaming, please visit our <a href="https://spinsways.gitbook.io/about/responsible-gambling" target='_blank' style={{color: "#8f87ff"}}>Responsible Gaming</a> page.</p>
            </div><br />
            <div>
                <strong>4. 24/7 Monitoring</strong>
                <p style={{fontSize: "15px"}}>Our team diligently monitors gameplay to uphold fairness and integrity.</p>
            </div><br />
            <div>
                <strong>5. Customer Support</strong>
                <p style={{fontSize: "15px"}}>For queries or concerns about fairness, contact our support team at any time.</p>
            </div>
        </div>
    );
};

export default Fairness;