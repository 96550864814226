import React, {useEffect, useState} from 'react';
import ActiveBalances from "./ActiveBalances";
import Withdraws from "./Withdraws";
import Deposits from "./Deposits";
import {useLocation, useNavigate} from "react-router-dom";
import {PATH} from "../../router/PATH";
import {refreshAction} from "../../redux_ver2/actions/auth.actions";
import {useDispatch} from "react-redux";

const Transactions = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(location.pathname);

    // const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(refreshAction());
    // }, []);

    const handleTabChange = (filter) => setActiveTab(filter);

    return (
        <div className='dropdown-transactions'>

            <h2 className={"security-title"}>Track the status of your transactions</h2>

            <div className='dropdown-transactions-btns' style={{marginTop: "20px"}}>
                <div className='dropdown-transactions-btns-beautify'>
                    <button
                        onClick={() => {
                            handleTabChange(PATH.DROPDOWN.TRANSACTIONS.BALANCES);
                            navigate(PATH.DROPDOWN.TRANSACTIONS.BALANCES, {
                                state: {background: location?.state?.background}
                            });
                        }}
                        className="dropdown-transactions-btns-all"
                        style={{background: activeTab === PATH.DROPDOWN.TRANSACTIONS.BALANCES ? "#46465e" : "initial"}}
                    >
                        Balance
                    </button>
                    <button
                        onClick={() => {
                            handleTabChange(PATH.DROPDOWN.TRANSACTIONS.DEPOSITS);
                            navigate(PATH.DROPDOWN.TRANSACTIONS.DEPOSITS, {
                                state: {background: location?.state?.background}
                            });
                        }}
                        className="dropdown-transactions-btns-deposits"
                        style={{background: activeTab === PATH.DROPDOWN.TRANSACTIONS.DEPOSITS ? "#46465e" : "initial"}}
                    >
                        Deposits
                    </button>
                    <button
                        onClick={() => {
                            handleTabChange(PATH.DROPDOWN.TRANSACTIONS.WITHDRAWS);
                            navigate(PATH.DROPDOWN.TRANSACTIONS.WITHDRAWS, {
                                state: {background: location?.state?.background}
                            });
                        }}
                        className="dropdown-transactions-btns-withdrawals"
                        style={{background: activeTab === PATH.DROPDOWN.TRANSACTIONS.WITHDRAWS ? "#46465e" : "initial"}}
                    >
                        Withdrawals
                    </button>
                </div>
            </div>

            <div style={{padding: "15px 7px 0 7px"}}>
                <div>
                    {activeTab === PATH.DROPDOWN.TRANSACTIONS.BALANCES && <ActiveBalances/>}
                    {activeTab === PATH.DROPDOWN.TRANSACTIONS.WITHDRAWS && <Withdraws/>}
                    {activeTab === PATH.DROPDOWN.TRANSACTIONS.DEPOSITS && <Deposits/>}
                </div>
            </div>

        </div>
    );
};

export default Transactions;
