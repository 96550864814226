import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {depositsSelector} from "../../redux_ver2/selector/wallet.selector";
import {useParams} from "react-router-dom";
import {QRCode} from "antd";
import {renderApiDate} from "../../utils/renderDate";
import "./qr.css";
import {depositCrypto} from "../../utils/deposit-crypto";

const QrPayment = () => {

    const [depositData, setDepositData] = useState(null);
    const [icon, setIcon] = useState("");
    const deposits = useSelector(depositsSelector);
    const {address} = useParams();


    useEffect(() => {
        if (address) {
            const deposit = deposits.find(d => d.address === address);
            if (deposit) {
                setDepositData(deposit);
                const iconCandidate = depositCrypto.find(item => item.code === deposit?.crypto);
                if (iconCandidate) setIcon(iconCandidate.icon);
            } else {
                setDepositData(null);
            }
        }
    }, []);

    const [copySuccess, setCopySuccess] = useState('');

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopySuccess('Copied to clipboard!');

            // Reset the message after 1 second
            setTimeout(() => {
                setCopySuccess('');
            }, 1000);
        }).catch(err => {
            console.error('Error in copying text: ', err);
            setCopySuccess('Failed to copy!');
        });
    };

    const renderQR = () =>
        <div
            className={"qr-container"}
            style={{display: "flex", justifyContent: "space-between"}}
        >

            <div 
            className='qr-image'
            style={{width: "50%"}}>
                <QRCode
                    size={300}
                    color={"white"}
                    bordered={true}
                    bgColor={"#191828"}
                    errorLevel="H"
                    value={depositData?.address}
                    icon={icon}
                />
            </div>


            <div className='deposit-window'>
                <div className='deposit-window-send-div'>
                    <p className="deposit-window-send-text">Send exact amount
                        of <b>{depositData?.amount} {depositData?.crypto?.toUpperCase()}</b> on &nbsp;
                        <br className='br-remove'/>
                        <b>{address}</b>
                    </p>
                    
                    <br/>
                    
                    <button
                        onClick={() => copyToClipboard(address)}
                        style={{marginLeft: "0px"}}
                        className='copy-btn copy-btn-deposit'
                    >
                        Click to Copy Address
                    </button>
                    <span style={{marginLeft: '10px'}}>{copySuccess}</span>
                </div>
                <br/>
                <div className='deposit-window-status-info'>
                    <p style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center"
                    }}>Status: {depositData?.status}{depositData?.status === "waiting" &&
                        <span style={{marginLeft: "5px"}} className="spinner"></span>}</p>
                    <p>Created at: {renderApiDate(depositData?.time)}</p>
                </div>
            </div>

        </div>

    return (
        <div>
            {
                depositData
                    ? renderQR()
                    : <h2 style={{textAlign: "center"}}>Incorrect payment address</h2>
            }
        </div>
    );
};

export default QrPayment;
