import AppLayout from "../pages/AppLayout";
import {PATH} from "./PATH";
import ResetPassword from "../pages/ResetPassword";
import {Navigate} from "react-router-dom";
import LoginFormWrapper from "../components/Auth/LoginFormWrapper";
import ChallengesWrapper from "../components/Challenges/ChallengesWrapper";
import SuperRoundsWrapper from "../components/SuperRounds/SuperRoundsWrapper";
import LuckyWheelWrapper from "../components/LuckyWheel/LuckyWheelWrapper";
import UserInfoWrapper from "../components/UserInfo/UserInfoWrapper";
import SecurityWrapper from "../components/Security/SecurityWrapper";
import TransactionsWrapper from "../components/Trsansactions/TransactionsWrapper";
import UserBetsWrapper from "../components/UserBets/UserBetsWrapper";
import DepositWrapper from "../components/Deposit/DepositWrapper";
import SignInFormWrapper from "../components/Auth/SignInFormWrapper";
import MobileLeaderboardWrapper from "../components/Mobile/MobileLeaderboardWrapper";
import SupportWrapper from "../components/Support/SupportWrapper";
import ForgotPasswordWrapper from "../components/Security/ForgotPasswordWrapper";
import FairnessWrapper from "../components/Fairness/FairnessWrapper";
import VerificationTool from "../pages/VerificationTool";
import QRPaymentWrapper from "../components/Deposit/QR-PaymentWrapper";

export const mainRoutes = (authorized) => {
    return authorized
        ? [
            {
                path: PATH.HOME,
                element: <Navigate to={PATH.GAME}/>
            },

            {
                path: PATH.GAME,
                element: <AppLayout/>
            },
            {
                path: PATH.RESET_PASSWORD,
                element: <ResetPassword/>
            },
            {
                path: PATH.FAIRNESS.FAIRNESS_VERIFICATION,
                element: <VerificationTool/>
            },
            {
                path: '*',
                element: <Navigate to={PATH.GAME}/>
            }
        ] :
        [
            {
                path: PATH.HOME,
                element: <Navigate to={PATH.GAME}/>
            },

            {
                path: PATH.GAME,
                element: <AppLayout/>
            },
            {
                path: PATH.RESET_PASSWORD,
                element: <ResetPassword/>
            },
            {
                path: PATH.FAIRNESS.FAIRNESS_VERIFICATION,
                element: <VerificationTool/>
            },
            {
                path: '*',
                element: <Navigate to={PATH.GAME}/>
            }
        ]

}

export const modalRoutes = (authorized) => {
    return authorized
        ? [
            {
                path: PATH.CHALLENGES,
                element: <ChallengesWrapper/>
            },
            {
                path: PATH.LUCKY_WHEEL,
                element: <LuckyWheelWrapper/>
            },
            {
                path: PATH.DROPDOWN.INFO,
                element: <UserInfoWrapper/>
            },
            {
                path: PATH.DROPDOWN.SECURITY.EMAIL,
                element: <SecurityWrapper/>
            },
            {
                path: PATH.DROPDOWN.SECURITY.PASSWORD,
                element: <SecurityWrapper/>
            },
            {
                path: PATH.DROPDOWN.BETS,
                element: <UserBetsWrapper/>
            },
            {
                path: PATH.DROPDOWN.WALLET.DEPOSIT,
                element: <DepositWrapper/>
            },
            {
                path: PATH.DROPDOWN.WALLET.WITHDRAW,
                element: <DepositWrapper/>
            },
            {
                path: PATH.DROPDOWN.TRANSACTIONS.BALANCES,
                element: <TransactionsWrapper/>
            },
            {
                path: PATH.DROPDOWN.TRANSACTIONS.WITHDRAWS,
                element: <TransactionsWrapper/>
            },
            {
                path: PATH.DROPDOWN.TRANSACTIONS.DEPOSITS,
                element: <TransactionsWrapper/>
            },
            {
                path: PATH.SUPER_ROUNDS,
                element: <SuperRoundsWrapper/>
            },
            {
                path: PATH.SUPPORT,
                element: <SupportWrapper/>
            },
            {
                path: PATH.LEADERBOARD,
                element: <MobileLeaderboardWrapper/>
            },
            {
                path: PATH.FORGOT_PASSWORD,
                element: <ForgotPasswordWrapper/>
            },
            {
                path: PATH.FAIRNESS.FAIRNESS_INFO,
                element: <FairnessWrapper/>
            },
            {
                path: PATH.QR_CODE_PAYMENT,
                element: <QRPaymentWrapper/>
            },
        ]
        : [
            {
                path: PATH.AUTH.LOG_IN,
                element: <LoginFormWrapper/>
            },
            {
                path: PATH.AUTH.SIGN_IN,
                element: <SignInFormWrapper/>
            },
            {
                path: PATH.SUPER_ROUNDS,
                element: <SuperRoundsWrapper/>
            },
            {
                path: PATH.SUPPORT,
                element: <SupportWrapper/>
            },
            {
                path: PATH.LEADERBOARD,
                element: <MobileLeaderboardWrapper/>
            },
            {
                path: PATH.FORGOT_PASSWORD,
                element: <ForgotPasswordWrapper/>
            },
            {
                path: PATH.FAIRNESS.FAIRNESS_INFO,
                element: <FairnessWrapper/>
            }
        ]
}