const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
};

export const renderApiDate = apiTime => {
    const apiDate = new Date(apiTime);
    const localTime = new Intl.DateTimeFormat("en-US", options).format(apiDate);

    return localTime;
}