import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {withdrawsSelector} from "../../redux_ver2/selector/wallet.selector";
import Offer, {OFFER_TYPES} from "../Deposit/Offer";
import WithdrawItem from "./WithdrawItem";
import socket from "../../socket";
import {userDataSelector} from "../../redux_ver2/selector/user.selector";
import Loader from "../Loader/Loader";

const Withdraws = () => {

    const user = useSelector(userDataSelector);

    const [loading, setLoading] = useState(false);
    const [withdraws, setWithdraws] = useState([]);

    useEffect(() => {
        if (user?.id) {
            socket.emit("wallet:get_withdraw", user.id);
            setLoading(true);
        }
        socket.on("wallet:get_withdraw", w => {
            setWithdraws(w);
            setLoading(false)
        });
    }, []);

    return (
        <div>
            {loading ?
                <Loader/> :
                <table>
                    <thead className='dropdown-transactions-table-thead'>
                    <tr>
                        <th>Amount</th>
                        <th>Crypto</th>
                        <th>Status</th>
                        <th>Address</th>
                        <th>Date</th>
                        <th>Email</th>
                        <th>Action</th>
                    </tr>
                    </thead>

                    {
                        withdraws?.length === 0 ?
                            <Offer
                                type={OFFER_TYPES.WITHDRAWS}
                            /> :
                            <tbody>
                            {
                                withdraws?.map((item, index) =>
                                    {
                                        return <WithdrawItem
                                            key={index}
                                            crypto={item?.crypto}
                                            address={item?.address}
                                            status={item?.status}
                                            time={item?.time}
                                            amount={item?.amount}
                                            _id={item?._id}
                                            email={item?.email}
                                            failMessage={item?.failMessage}
                                            networkTransaction={item?.networkTransaction}
                                            withdrawId={item.withdrawId}
                                        />
                                    }

                                )
                            }
                            </tbody>
                    }
                </table>
            }
        </div>
    );
};

export default Withdraws;