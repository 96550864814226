import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    DollarOutlined,
    HistoryOutlined, InfoCircleOutlined, KeyOutlined,
    LockOutlined,
    LogoutOutlined,
    UserOutlined,
    WalletOutlined
} from "@ant-design/icons";
import { Dropdown } from "antd";
import "./navbar.css";
import { PATH } from "../../router/PATH";
import { userDataSelector } from "../../redux_ver2/selector/user.selector";
import { logoutAction } from "../../redux_ver2/actions/auth.actions";

const DropDownMenu = () => {
    const location = useLocation();
    const user = useSelector(userDataSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isPhone = window.innerWidth < 599;

    // Define styles with conditional fontSize
    const styles = {
        fontSize: isPhone ? "14px" : "17px", // Adjust font size based on device
        fontWeight: "bold",
        textDecoration: "none",
        display: "inline",
    };

    const handleLogout = () => dispatch(logoutAction());

    let items = [
        {
            label: <p onClick={() => {
                        navigate(PATH.DROPDOWN.INFO, {state: {background: location}});
                    }}
                    style={styles}>
                <InfoCircleOutlined className={"dropdown-icon"} style={styles}/>
                My Account
            </p>,
            key: 'Info',
        },
        { type: "divider" },
        {
            label: <p onClick={() => {
                        navigate(PATH.DROPDOWN.WALLET.DEPOSIT, {state: {background: location}});
                    }}
                    style={styles}>
                <WalletOutlined className={"dropdown-icon"} style={styles}/>
                Deposit & Withdraw
            </p>,
            key: 'Wallet',
        },
        { type: "divider" },
        {
            label: <p onClick={() => {
                        navigate(PATH.DROPDOWN.TRANSACTIONS.BALANCES, {state: {background: location}});
                    }}
                    style={styles}>
                <DollarOutlined className={"dropdown-icon"} style={styles}/>
                Transactions
            </p>,
            key: 'Transactions',
        },
        { type: "divider" },
        {
            label: <p onClick={() => {
                        navigate(PATH.DROPDOWN.BETS, {state: {background: location}});
                    }}
                    style={styles}>
                <HistoryOutlined className={"dropdown-icon"} style={styles}/>
                My Bets
            </p>,
            key: 'My Bets',
        },
        { type: "divider" },
        {
            label: <p onClick={() => {
                        navigate(PATH.DROPDOWN.SECURITY.EMAIL, {state: {background: location}});
                    }}
                    style={styles}>
                <LockOutlined className={"dropdown-icon"} style={styles}/>
                Security
            </p>,
            key: "Security",
        },
        { type: "divider" },
    ];

    // Conditionally add "Verification Tool" item based on isPhone
    if (!isPhone) {
        items.push(
            {
                label: <p onClick={() => navigate(PATH.FAIRNESS.FAIRNESS_VERIFICATION)}
                           style={styles}>
                    <KeyOutlined className={"dropdown-icon"}/>
                    Verification Tool
                </p>,
                key: "Verification Tool"
            },
            { type: "divider" }
        );
    }

    // Add logout item last to ensure it appears at the bottom
    items.push(
        {
            label: <p style={styles} onClick={handleLogout}>
                Logout
            </p>,
            key: "Logout",
            icon: <LogoutOutlined style={styles} onClick={handleLogout}/>,
            danger: true
        }
    );

    return (
        <Dropdown menu={{ items }} trigger={["click"]}>
            <p style={{fontSize: isPhone ? "17px" : "20px", cursor: "pointer"}}>
                <UserOutlined style={{margin: "0 10px 0 0", fontSize: isPhone ? "17px" : "20px"}}/>
                {user?.username}
            </p>
        </Dropdown>
    );
};

export default DropDownMenu;
