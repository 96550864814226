import React, {useEffect, useState} from 'react';
import socket from "../../socket";
import GameGraph from "./GameGraph";

const Crasher = () => {

    const [liveMultiplier, setLiveMultiplier] = useState(1.00);
    const [stopwatch, setStopwatch] = useState(3.00);

    const [connection, setConnection] = useState(false);
    const [isGameRunning, setIsGameRunning] = useState(false);
    const [isStopwatch, setIsStopwatch] = useState(false);

    const [crashMessage, setCrashMessage] = useState("");

    useEffect(() => {
        socket.on("bettor:set_connection", conn => setConnection(conn));

        socket.on("game:multiplier", multiplier => setLiveMultiplier(multiplier));
        socket.on("game:crash", msg => setCrashMessage(msg));
        socket.on("game:is_stopwatch", state => setIsStopwatch(state));
        socket.on("game:stopwatch_runner", runner => setStopwatch(runner));
        socket.on("game:state", state => setIsGameRunning(state));

    }, []);

    useEffect(() => {
        socket.emit("bettor:refresh_bet_history");
    }, [isGameRunning]);


    useEffect(() => {
        if (!connection) {
            socket.emit("bettor:reconnect");
            socket.emit("bettor:resend_online");
            socket.emit("bettor:resend_game_history");
            socket.emit("bettor:resend_stopwatch_state");
            socket.emit("bettor:resend_crash_message");
        }
    }, [connection]);

    const render = () => (
        <div className={"game-phase-render"}>
            <div>
                {
                    !connection
                        ? "CONNECTING..."
                        : (isGameRunning && connection)
                            ? `${liveMultiplier}x`
                            : <p style={{color: !isStopwatch && "red"}}>{isStopwatch ? stopwatch : crashMessage}</p>
                }
            </div>
        </div>
    )

    return (
        <div className={"game"}>
            {render()}
            <GameGraph/>
        </div>
    );
};

export default Crasher;