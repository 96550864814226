import usdt from "../assets/icons/usdt@2x.png";
import btc from "../assets/icons/btc@2x.png";
import bnb from "../assets/icons/bnb@2x.png";
import sol from "../assets/icons/sol@2x.png";
import ape from "../assets/icons/ape@2x.png";
import doge from "../assets/icons/doge@2x.png";
import matic from "../assets/icons/matic@2x.png";
import link from "../assets/icons/link@2x.png";
import ltc from "../assets/icons/ltc@2x.png";
import eth from "../assets/icons/eth@2x.png";
import xrp from "../assets/icons/xrp@2x.png";

export const depositCrypto = [

    {
        code: "usdttrc20",
        label: "USDT (TRC20)",
        icon: usdt
    },
    {
        code: "btc",
        label: "Bitcoin $BTC",
        icon: btc
    },
    {
        code: "eth",
        label: "Ethereum $ETH",
        icon: eth
    },
    {
        code: "bnbbsc",
        label: "BNB (BEP20)",
        icon: bnb
    },
    {
        code: "ltc",
        label: "Litecoin $LTC",
        icon: ltc
    },
    {
        code: "sol",
        label: "Solana $SOL",
        icon: sol
    },
    {
        code: "matic",
        label: "Polygon $MATIC",
        icon: matic
    },
    {
        code: "ape",
        label: "ApeCoin $APE",
        icon: ape
    },
    {
        code: "link",
        label: "Chainlink $LINK",
        icon: link
    },
    {
        code: "xrp",
        label: "Ripple $XRP",
        icon: xrp
    },
    {
        code: "doge",
        label: "Dogecoin $DOGE",
        icon: doge
    }
]