export const requiredRule = message => (
    {
        required: true,
        message: message
    }
);

export const emailValidationRule = () => (
    {
        type: "email",
        message: "Input a valid email"
    }
);

export const passwordMinLengthRule = () => (
    {
        min: 8,
        message: "Password should contains minimum 8 symbols"
    }
);

export const passwordMaxLengthRule = () => (
    {
        max: 32,
        message: "Password too long"
    }
);

export const usernameMinLengthRule = () => (
    {
        min: 4,
        message: "Username should contains minimum 4 symbols"
    }
);

export const usernameMaxLengthRule = () => (
    {
        max: 24,
        message: "Username too long"
    }
);