import {DECREASE_MESSAGES_COUNT, FEEDBACK, FEEDBACK_CLEAR, INCREASE_MESSAGES_COUNT} from "../actions/feedback.actions";
import {QR_REDIRECT} from "../actions/wallet.actions";

const initialState = {

    message: "",
    isError: false,
    messageCount: 0,

    qrRedirect: false,
    link: "",
}

export const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {

        case DECREASE_MESSAGES_COUNT:
            return {
                ...state,
                messageCount: state.messageCount - 1,
            }

        case INCREASE_MESSAGES_COUNT:
            return {
                ...state,
                messageCount: state.messageCount + 1
            }

        case FEEDBACK:
            return  {
                ...state,
                message: action.payload.message,
                isError: action.payload.isError,
            }

        case FEEDBACK_CLEAR:
            return {
                ...state,
                message: "",
                isError: false,
            }

        case QR_REDIRECT:
            return {
                ...state,
                qrRedirect: action.payload.redirect,
                link: action.payload.link,
            }

        default:
            return state;
    }
}