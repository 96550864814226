import React from 'react';
import ModalPage from "../../pages/ModalPage";
import LoginForm from "./LoginForm";

const LoginFormWrapper = () => {
    return (
        <ModalPage
            component={<LoginForm/>}
            title={"Login"}
        >

        </ModalPage>
    );
};

export default LoginFormWrapper;