import React from 'react';
import GameInfoSidebar from "./GameInfoSidebar";
import "./game.css";
import Game from "./Game";

const GameWrapper = () => {
    return (
        <>
            <div className={"game-active-container"}>
                <Game/>
                <GameInfoSidebar/>
            </div>
        </>
    );
};

export default GameWrapper;