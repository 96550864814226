import React, {useState} from 'react';
import {Button, Form, Input} from "antd";
import {emailValidationRule, requiredRule} from "../../utils/formRulesValidation";
import {useDispatch} from "react-redux";
import {forgotPasswordAction} from "../../redux_ver2/actions/security.actions";

const ForgotPassword = () => {

    const dispatch = useDispatch();
    const [email, setEmail] = useState("");

    const handleEmailChange = e => setEmail(e.target.value);

    const onFinish = () => dispatch(forgotPasswordAction(email));

    return (
        <div>

            <h2 className={"security-title"}>Password recovery</h2>

            <Form
                autoComplete={"off"}
                onFinish={onFinish}
            >
                <Form.Item
                    name={"email"}
                    rules={[
                        requiredRule("Input your account`s email"),
                        emailValidationRule()
                    ]}
                    label={"Email"}
                >
                    <Input
                        placeholder={'email@example.com'}
                        value={email}
                        onChange={handleEmailChange}
                    />
                </Form.Item>

                <Button type={"primary"} htmlType={"submit"}>Confirm email</Button>
            </Form>
        </div>
    );


};

export default ForgotPassword;