import axios  from "axios";

// PROD
export const API_URL = `https://spinsways-production.up.railway.app/api`;

// DEV
// export const API_URL = `http://localhost:8080/api`;

export const PAYMENT_API_URL = `https://api.nowpayments.io/v1`;

const $api = axios.create({
    // чтобы к каждому запросу цеплялись куки
    withCredentials: true,
    baseURL: API_URL
})


$api.interceptors.request.use(cfg => {
    cfg.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return cfg;
});

export const $payment_api = axios.create({
    baseURL: PAYMENT_API_URL
})

$payment_api.interceptors.request.use(cfg => {
   cfg.headers["x-api-key"] = "9RKGEPC-AV24K7A-PVN9D2H-N73EMWJ";
   return cfg;
});


export default $api;