import React, {useContext, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {BackgroundContext} from "../context/background.context";
import {PATH} from "../router/PATH";
import "./modal.css";
import {Divider} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {qrRedirectSelector} from "../redux_ver2/selector/feedback.selector";
import {resetRedirectAction} from "../redux_ver2/actions/wallet.actions";

const ModalPage = ({component, title}) => {

    const dispatch = useDispatch();

    const isRedirect = useSelector(qrRedirectSelector);

    const navigate = useNavigate();
    const {background} = useContext(BackgroundContext);

    const handleModalClose = () => {
        navigate(background?.pathname || PATH.HOME);
        if (isRedirect) dispatch(resetRedirectAction())
    }
    const handleEmptySpaceClose = (event) => {
        const modalContainer = document.querySelector(".modal-blur");
        if (modalContainer === event.target) handleModalClose();
    }

    const handleEscClose = (event) => {
        if (event.key === 'Escape') handleModalClose();
    }

    useEffect(() => {
        document.body.style.overflow = "hidden";
        document.addEventListener('keydown', handleEscClose);
        document.addEventListener('click', handleEmptySpaceClose);

        return () => {
            document.body.style.overflow = "auto";
            document.removeEventListener('keydown', handleEscClose);
            document.removeEventListener('click', handleEmptySpaceClose);
        }
    }, []);


    return (
        <div className="modal-blur">
            <div className="modal-container">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>
                            {title}
                        </h2>
                        <span className={"modal-close-btn"} onClick={handleModalClose}>&times;</span>

                        <Divider className='modal-divider' style={{border: "1px solid orange"}}/>
                    </div>

                    {component}
                </div>
            </div>
        </div>
    );
};

export default ModalPage;