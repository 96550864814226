import {io} from "socket.io-client";

// PROD
export const SOCKET_URL = "wss://spinsways-production.up.railway.app";

// LOCAL | DEV
// export const SOCKET_URL = "ws://localhost:8080";

const socket = io(SOCKET_URL);

// socket.on() - получать сообщения от сервера
// socket.emit() - отправлять на сервер

export default socket;