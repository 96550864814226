import React from 'react';
import supround from "../../assets/mobileImages/supround.png";
import luckywheel from "../../assets/mobileImages/luckywheel.png";
import quests from "../../assets/mobileImages/quests.png";
import leader from "../../assets/mobileImages/leader.png";
import support from "../../assets/mobileImages/support.png";
import {useLocation, useNavigate} from "react-router-dom";
import {PATH} from "../../router/PATH";
import "./mobile-navbar.css";
import {useSelector} from "react-redux";
import {isAuthSelector} from "../../redux_ver2/selector/user.selector";

const MobileNavbar = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const isAuth = useSelector(isAuthSelector);

    return (
        <section className="mobile-footer">
            <div className="mobile-footer-menu">
                <p
                    className="mobile-footer-btn"
                    onClick={() => {
                        navigate(isAuth ? PATH.SUPER_ROUNDS : PATH.AUTH.SIGN_IN, {
                            state: { background: location }
                        })
                    }}
                >
                    <img src={supround} alt="SupRound"/>
                    <p>SupRound</p>
                </p>

                <p
                    className="mobile-footer-btn"
                    onClick={() => {
                        navigate(isAuth ? PATH.LUCKY_WHEEL : PATH.AUTH.SIGN_IN, {
                            state: { background: location }
                        })
                    }}
                >
                    <img src={luckywheel} alt="LuckyWheel"/>
                    <p>Wheely</p>
                </p>

                <p
                    className="mobile-footer-btn"
                    onClick={() => {
                        navigate(isAuth ? PATH.CHALLENGES : PATH.AUTH.SIGN_IN, {
                            state: { background: location }
                        })
                    }}
                >
                    <img src={quests} alt="quests"/>
                    <p>Challenges</p>
                </p>

                <p
                    className="mobile-footer-btn"
                    onClick={() => {
                        navigate(PATH.LEADERBOARD, {
                            state: { background: location }
                        })
                    }}
                >
                    <img src={leader} alt="Leaders"/>
                    <p>Leaders</p>
                </p>

                <p
                    className="mobile-footer-btn"
                    onClick={() => {
                        navigate(PATH.SUPPORT, {
                            state: { background: location }
                        })
                    }}
                >
                    <img src={support} alt="Support"/>
                    <p>Support</p>
                </p>
            </div>
        </section>
    );
};

export default MobileNavbar;