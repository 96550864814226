import React from 'react';
import {useSelector} from "react-redux";
import {depositsSelector} from "../../redux_ver2/selector/wallet.selector";
import Offer from "../Deposit/Offer";
import DepositItem from "./DepositItem";

const Deposits = () => {

    const deposits = useSelector(depositsSelector);

    return (
        <div>
            <table>
                <thead className='dropdown-transactions-table-thead'>
                <tr>
                    <th>Amount</th>
                    <th>Crypto</th>
                    <th>Status</th>
                    <th  className='transactions-phone-version'>Address</th>
                    <th  className='transactions-phone-version'>Date</th>
                    <th>Actions</th>
                </tr>
                </thead>

                {
                    deposits.length === 0 ?
                        <Offer/> :
                        <tbody>
                        {
                            deposits?.map((item, index) =>
                                <DepositItem
                                    key={index}
                                    amount={item?.amount}
                                    time={item?.time}
                                    status={item?.status}
                                    address={item?.address}
                                    crypto={item?.crypto}
                                />
                            )
                        }
                        </tbody>
                }
            </table>
        </div>

    );
};

export default Deposits;