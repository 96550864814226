import React from 'react';
import ModalPage from "../../pages/ModalPage";
import SuperRounds from "./SuperRounds";

const SuperRoundsWrapper = () => {
    return (
        <ModalPage
            component={<SuperRounds/>}
            title={"Super Rounds"}
        />
    );
};

export default SuperRoundsWrapper;