import React, {useState} from 'react';
import {Button, Divider, Form, Input} from "antd";
import Password from "antd/es/input/Password";
import {
    emailValidationRule,
    passwordMaxLengthRule,
    passwordMinLengthRule,
    requiredRule,
    usernameMaxLengthRule,
    usernameMinLengthRule
} from "../../utils/formRulesValidation";
import "./auth.css";
import {useLocation, useNavigate} from "react-router-dom";
import {PATH} from "../../router/PATH";
import {useDispatch} from "react-redux";
import {registerAction} from "../../redux_ver2/actions/auth.actions";

const SignInForm = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [redeem, setRedeem] = useState(null);

    const handleEmailChange = e => setEmail(e.target.value);
    const handlePasswordChange = e => setPassword(e.target.value);
    const handleUsername = e => setUsername(e.target.value);
    const handleRedeemCode = e => setRedeem(e.target.value);

    const validateNoSpaces = (_, value) => {
        if (value && /\s/.test(value)) {
            return Promise.reject("username shouldn't contains spaces");
        }
        return Promise.resolve();
    };

    const onFinish = () => dispatch(registerAction(username, email, password, redeem));

    return (
        <div className={"auth-container"}>

            <h1 className={"auth-header"}>Welcome!</h1>

            <Form
                onFinish={onFinish}
                autoComplete="off"
                style={{marginTop: "10px"}}
            >
                <Form.Item
                    rules={[
                        requiredRule("Username is Required"),
                        usernameMinLengthRule(),
                        usernameMaxLengthRule(),
                        { validator: validateNoSpaces },
                    ]}
                    name={"username"}
                >
                    <Input
                        placeholder={"Username"}
                        value={username}
                        onChange={handleUsername}
                        className='auth-mobile-input-size'
                    />
                </Form.Item>

                <Form.Item
                    rules={[
                        requiredRule("Email is required"),
                        emailValidationRule()
                    ]}
                    name={"email"}
                >
                    <Input
                        placeholder={"Email"}
                        value={email}
                        onChange={handleEmailChange}
                        className='auth-mobile-input-size'
                    />
                </Form.Item>

                <Form.Item
                    name={"password"}
                    rules={[
                        requiredRule("Password is required"),
                        passwordMinLengthRule(),
                        passwordMaxLengthRule()
                    ]}
                >
                    <Password
                        placeholder={"Password"}
                        value={password}
                        onChange={handlePasswordChange}
                        className='auth-mobile-input-size'
                    />
                </Form.Item>

                <Form.Item
                    name={"redeem"}
                >
                    <Input
                        value={redeem}
                        onChange={handleRedeemCode}
                        placeholder={"Referral Code (optional)"}
                        className='auth-mobile-input-size'
                    />
                </Form.Item>

                <Button
                    style={{margin: "15px 0 0 0"}}
                    htmlType={'submit'}
                >Register</Button>
            </Form>

            <Divider style={{border: "1px solid grey"}}/>

            <div className={"auth-footer"}>
                <p className={"auth-footer-text"}>Already have account?</p>
                <p
                    onClick={() => {
                        navigate(PATH.AUTH.LOG_IN, {
                                state: {background: location?.state?.background}
                        })
                    }}
                    className={"auth-footer-link"}
                >
                    Login
                </p>
            </div>

        </div>
    );
};

export default SignInForm;