import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {PATH} from "../../router/PATH";
import DropDownMenu from "./DropDownMenu";
import logo from "../../assets/images/logo.png";
import "./navbar.css";
import {isAuthSelector} from "../../redux_ver2/selector/user.selector";
import {preloaderSelector} from "../../redux_ver2/selector/loading.selector";

const Navbar = () => {

    const location = useLocation();
    const isAuth = useSelector(isAuthSelector);
    const navigate = useNavigate();
    const preloader = useSelector(preloaderSelector);

    return (
        <header>
            <div style={{padding: "0 10px 0 10px"}} className='header container'>
                <img
                    alt={"SpinSways.com"}
                    style={{cursor: "default"}}
                    className='logo'
                    src={logo}

                />
                <div className='hero'>
                    <p
                        style={{display: "inline"}}
                        className='hero-text'
                        onClick={() => {
                            navigate(isAuth ? PATH.SUPER_ROUNDS : PATH.AUTH.SIGN_IN, {
                                state: { background: location }
                            })
                        }}
                    >
                        Super Rounds
                    </p>
                    <p
                        style={{display: "inline"}}
                        className='hero-text'
                        onClick={() => {
                            navigate(isAuth ? PATH.LUCKY_WHEEL : PATH.AUTH.SIGN_IN, { state: { background: location } })
                        }}
                    >
                        Lucky Wheel
                    </p>
                    <p
                        style={{display: "inline"}}
                        className='hero-text'
                        onClick={() => {
                            navigate(isAuth ? PATH.CHALLENGES : PATH.AUTH.SIGN_IN, { state: { background: location } })
                        }}
                    >
                        Challenges
                    </p>
                </div>
                <div className={"user-account"}>
                    {(!isAuth && !preloader) ?
                        <>
                            <p
                                className='deposit-btn'
                                onClick={() => {
                                    navigate(PATH.AUTH.LOG_IN, { state: { background: location } })
                                }}
                            >
                                Login
                            </p>
                            <p
                                className='withdraw-btn'
                                onClick={() => {
                                    navigate(PATH.AUTH.SIGN_IN, { state: { background: location } })
                                }}
                            >
                                Register
                            </p>
                        </> :
                        <div style={{display: "flex", alignItems: "center"}}>
                            <DropDownMenu/>
                        </div>
                    }
                </div>
            </div>
        </header>
    );
};

export default Navbar;