import React from 'react';
import Crasher from "./Crasher";
import BetPanel from "./BetPanel";

const Game = () => {
    return (
        <>
            <div className={"game-main game-area"}>
                <div className={"game-screen"}>
                    <Crasher/>
                </div>
                <div style={{margin: "30px 0 0 0"}}>
                    <BetPanel/>
                </div>
            </div>
        </>
    );
};

export default Game;