import React, {useState} from 'react';
import {Button, Input} from "antd";
import {renderApiDate} from "../../utils/renderDate";
import {PATH} from "../../router/PATH";
import {useLocation, useNavigate} from "react-router-dom";

const DepositItem = ({key, amount, crypto, status, address, time}) => {

    const location = useLocation();
    const navigate = useNavigate();
    const [showInput, setShowInput] = useState(false);

    return (
        <tr key={key}>
            <td>{amount}</td>
            <td>{crypto?.toUpperCase()}</td>
            <td 
            className='deposit-statuses-transactions'
            style={{
                color:
                    status === "waiting" ? "orange" :
                        status === "FAILED" ? "red" :
                            status === "SUCCESS" ? "green" :
                                status === "CANCELED" ? "grey" :
                                    status === "PENDING" ? "blue" : "inherit",
                fontWeight: "bold",
                fontSize: "20px",
                display: "flex",
                alignItems: "center"
            }}>
                {status === "waiting" ? (
                    <>
                        <div className="spinner"></div>
                        <span className='deposit-statuses-transactions' style={{ marginLeft: "5px" }}>{status}</span>
                    </>
                ) : (
                    status
                )}
            </td>

            <td  className='transactions-phone-version'>
                {address?.length > 10 ? (
                    // Render an input element if the address is longer than 10 characters
                    showInput ? (
                        <Input
                            value={address}
                            readOnly
                            style={{
                                fontSize: "12px"
                            }}
                            onClick={() => {
                                // Handle input click event if needed
                            }}
                        />
                    ) : (
                        // Display the first 10 characters and a "Show More" button
                        <>
                            {address?.substr(0, 10)}...
                            <Button
                                type="link"
                                style={{
                                    color: "#6565C6"
                                }}
                                onClick={() => setShowInput(true)}
                            >
                                Show Address
                            </Button>
                        </>
                    )
                ) : (
                    // Display the address as is if it's 10 characters or less
                    address
                )}
            </td>

            <td  className='transactions-phone-version'>
                {
                    renderApiDate(time)
                }
            </td>
            <td>
                {(status === "waiting" || status === "PENDING") &&
                    <Button
                        type={"primary"}
                        style={{margin: "0 0 0 20px"}}
                        onClick={() => {
                            navigate(PATH.paymentAddress(address), {
                                state: {background: location?.state?.background}
                            });
                        }}
                    >Open a QR Code</Button>
                }
            </td>

        </tr>
    );
};

export default DepositItem;
