import React, {useEffect, useState} from 'react';
import {ConfigProvider, Select} from "antd";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {PATH} from "../../router/PATH";
import {balancesSelector} from "../../redux_ver2/selector/wallet.selector";
import {isAuthSelector} from "../../redux_ver2/selector/user.selector";
import {depositCrypto} from "../../utils/deposit-crypto";

const SelectCrypto = ({handleOnChange}) => {

    const location = useLocation();
    const isAuth = useSelector(isAuthSelector);

    const balances = useSelector(balancesSelector);
    const [selectedCrypto, setSelectedCrypto] = useState(null);

    useEffect(() => {
        if (localStorage.getItem("crypto")) {
            if (isAuth && balances.find(b => b.crypto === localStorage.getItem("crypto"))) {
                setSelectedCrypto(localStorage.getItem("crypto"));
            }
        }
    }, [isAuth, balances]);


    const handleCryptoChange = (val) => {

        if (location.pathname === PATH.GAME) {
            localStorage.setItem('crypto', val);
        }
        setSelectedCrypto(val);

        if (handleOnChange) {
            handleOnChange(val);
        }
    }

    useEffect(() => {
        if (!isAuth) {
            setSelectedCrypto(null);
        }
    }, [isAuth]);

    const filterBalances = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorIconHover: "#fff",
                    colorPrimary: "orange",
                },
                components: {
                    Select: {
                        colorBgContainerDisabled: "#252539",
                        colorTextDisabled: "grey",
                    }
                }
            }}
        >

            <Select
                className={"select-crypto"}
                dropdownStyle={{background: "#181928", zIndex: 10000000}}
                style={{color: "#fff"}}

                // showSearch
                optionFilterProp="children"
                filterOption={filterBalances}

                onChange={handleCryptoChange}
                placeholder="Select Crypto"

                value={selectedCrypto}

                options={
                    balances?.map((item) => {
                        let b = depositCrypto.find(dc => dc.code === item.crypto);

                        return {
                            value: item?.crypto,
                            balance: item?.amount,
                            label: (
                                (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={b?.icon} alt={b.label} style={{ marginRight: 8, width: '24px', height: '24px' }} />
                                        {b.label}
                                    </div>
                                )
                            )
                        };
                    })}
            />
        </ConfigProvider>
    );
};

export default SelectCrypto;
