import {combineReducers} from "redux";
import {userReducer} from "./user.reducer";
import {betHistoryReducer} from "./bet-history.reducer";
import {walletReducer} from "./wallet.reducer";
import {loadingReducer} from "./loading.reducer";
import {feedbackReducer} from "./feedback.reducer";

export const rootReducer = combineReducers({
    user: userReducer,
    betHistory: betHistoryReducer,
    wallet: walletReducer,
    load: loadingReducer,
    feedback: feedbackReducer
});