import React, { useEffect, useState } from "react";
import LiveBettors from "./LiveBettors";
import GameHistory from "./GameHistory";
import socket from "../../socket";

const GameInfoSidebar = () => {
  // state for current bettors
  const [currentBettors, setCurrentBettors] = useState([]);
  // state for game history
  const [gameHistory, setGameHistory] = useState([]);
  // state to switch between tabs
  const [currentBettorsActiveTab, setCurrentBettorsActiveTab] = useState(false);

  const [connections, setConnections] = useState(0);
  const [bettorsCount, setBettorsCount] = useState(0);

  // get data from socket
  useEffect(() => {
    socket.on("game:bettors", (bettors) => setCurrentBettors(bettors));
    socket.on("game:history", (history) => setGameHistory(history));

    // вроде должно быть без ошибок
    socket.on("game:connections", (count) => setConnections(count + 40)); /*в первый день ориг пусть будет потом увеличим*/
    socket.on("game:bettors_count", (count) => setBettorsCount(count));
  }, []);

  // switch between tabs
  const switchHistoryTab = () => setCurrentBettorsActiveTab(false);
  const switchBettorsTab = () => setCurrentBettorsActiveTab(true);

  return (
    <>
      <div className="left-main-container">
        <div className="fixed-header">
          {/* tabs to switch between bettors and history */}
          <div className="left-container-btn">
            {/* ------ tabs ------ */}
            <div className="left-container-btn-beautify">
              <button
                onClick={switchHistoryTab}
                className="history-btn"
                style={{ background: !currentBettorsActiveTab && "#46465e" }}
              >
                History
              </button>
              {/*<button*/}
              {/*  onClick={switchBettorsTab}*/}
              {/*  className="live-bets-btn"*/}
              {/*  style={{ background: currentBettorsActiveTab && "#46465e" }}*/}
              {/*>*/}
              {/*  Live Bets*/}
              {/*</button>*/}
            </div>
            <div className="left-container-stats-div">
              <p className="stats-online">
                Online:{" "}
                <span className="stats-numbers-blink">{connections}</span>
              </p>
              {/* <p className="stats-playing">
                Playing:{" "}
                <span className="stats-numbers-blink">{bettorsCount}</span>
              </p> */}
            </div>
          </div>
        </div>
        <div>
          {currentBettorsActiveTab ? (
            <LiveBettors liveBettors={currentBettors} />
          ) : (
            <GameHistory gameHistory={gameHistory} />
          )}
        </div>
      </div>

      <main className="phone-grid">
        <div className="phone-main-container">
          <div className="phone-history-livebets-container">
            <div className="phone-history-livebets">
              <div className="phone-history-livebets-btns">
                <button
                  className={!currentBettorsActiveTab ? "active" : ""}
                  onClick={switchHistoryTab}
                >
                  History
                </button>
                {/*<button*/}
                {/*  className={currentBettorsActiveTab ? "active" : ""}*/}
                {/*  onClick={switchBettorsTab}*/}
                {/*>*/}
                {/*  Live Bets*/}
                {/*</button>*/}
              </div>

              {currentBettorsActiveTab ? (
                <LiveBettors liveBettors={currentBettors} />
              ) : (
                <GameHistory gameHistory={gameHistory} />
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default GameInfoSidebar;
