import React from 'react';
import ModalPage from "../../pages/ModalPage";
import SignInForm from "./SignInForm";

const SignInFormWrapper = () => {
    return (
        <ModalPage
            component={<SignInForm/>}
            title={"Register"}
        />
    );
};

export default SignInFormWrapper;