import React from 'react';
import {useSelector} from "react-redux";
import {balancesSelector} from "../../redux_ver2/selector/wallet.selector";
import Offer from "../Deposit/Offer";

const ActiveBalances = () => {

    const balances = useSelector(balancesSelector);

    return (
        <div>
            <table>
                <thead className='dropdown-transactions-table-thead'>
                    <tr>
                        <th>Balance</th>
                        <th>Crypto</th>
                    </tr>
                </thead>

                {
                    balances.length === 0 ?
                        <Offer/> :
                        <tbody>
                        {
                            balances?.map((item, index) =>
                                <tr key={index}>
                                    <td>{item?.amount}</td>
                                    <td>{item?.crypto}</td>
                                </tr>
                            )
                        }
                        </tbody>
                }
                <tbody>
                </tbody>
            </table>
        </div>
    );
};

export default ActiveBalances;