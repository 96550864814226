import React from 'react';
import ModalPage from "../../pages/ModalPage";
import Support from "./Support";

const SupportWrapper = () => {
    return (
        <ModalPage
            component={<Support/>}
            title={"Support"}
        />
    );
};

export default SupportWrapper;