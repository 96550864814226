import React, {useState} from 'react';
import {Button, Form, Input} from "antd";
import {
    emailValidationRule,
    requiredRule
} from "../../utils/formRulesValidation";
import Password from "antd/es/input/Password";
import {useDispatch, useSelector} from "react-redux";
import {userDataSelector} from "../../redux_ver2/selector/user.selector";
import {changeEmailAction} from "../../redux_ver2/actions/security.actions";

const ChangeEmail = () => {

    const dispatch = useDispatch();

    const user = useSelector(userDataSelector);

    const [newEmail, setNewEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleNewEmailChange = e => setNewEmail(e.target.value);
    const handlePasswordChange = e => setPassword(e.target.value);

    const onFinish = () => dispatch(changeEmailAction(user?.email, newEmail, password));

    return (
        <>
            <h2 style={{marginBottom: "20px"}}>Change email address</h2>

            <Form
                onFinish={onFinish}
                autoComplete={"off"}
            >
                <Form.Item
                    name="newEmail"
                    label="New email"
                    rules={[
                        requiredRule("Enter new email"),
                        emailValidationRule(),
                    ]}
                >
                    <Input
                        value={newEmail}
                        onChange={handleNewEmailChange}
                    />
                </Form.Item>

                <Form.Item
                    name={"password"}
                    label={"Password"}
                    rules={[
                        requiredRule("Password is required")
                    ]}
                >
                    <Password
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </Form.Item>

                <Button type={"primary"} htmlType={"submit"}>Submit</Button>
            </Form>
        </>
    );
};

export default ChangeEmail;