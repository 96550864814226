import React from 'react';
import ModalPage from "../../pages/ModalPage";
import Fairness from "./Fairness";

const FairnessWrapper = () => {
    return (
        <ModalPage
            component={<Fairness/>}
            title={"Fairness"}
        />
    );
};

export default FairnessWrapper;