import React, {useEffect} from 'react';
import {toast, ToastContainer} from "react-toastify";
import socket from "../../socket";
import {useDispatch, useSelector} from "react-redux";
import {isErrorSelector, messageCountSelector, messageSelector} from "../../redux_ver2/selector/feedback.selector";
import {
    clearFeedbackAction, decreaseMessagesCount,
    increaseMessagesCount,
    listenSocketFeedbackAction
} from "../../redux_ver2/actions/feedback.actions";


// config - https://fkhadra.github.io/react-toastify/introduction/
const ToastConfigProviderWrapper = () => {

    const TOAST_AUTO_CLOSE_TIME = 2500;

    const dispatch = useDispatch();

    const feedbackMessage = useSelector(messageSelector);
    const isError = useSelector(isErrorSelector);
    const messagesCount = useSelector(messageCountSelector);

    useEffect(() => {
        socket.on("bettor:feedback", feedback => dispatch(listenSocketFeedbackAction(feedback?.isError, feedback?.message)));
    }, []);

    useEffect(() => {
        if (feedbackMessage && feedbackMessage?.length > 0 && messagesCount < 2) {
            isError ? toast.error(feedbackMessage) : toast.success(feedbackMessage);
            dispatch(increaseMessagesCount());

            let timeoutId = setTimeout(() => {
                dispatch(clearFeedbackAction());
                dispatch(decreaseMessagesCount());

                clearTimeout(timeoutId);
            }, TOAST_AUTO_CLOSE_TIME + 100);
        }
    }, [feedbackMessage]);

    return (
        <ToastContainer
            style={{zIndex: 10000000}}
            position="top-right"
            autoClose={TOAST_AUTO_CLOSE_TIME}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="dark"
        />
    );
};

export default ToastConfigProviderWrapper;