import React from "react";

const GameHistory = (props) => {
  // get gameHistory array from props
  const { gameHistory } = props;

  return (
    <>
      <table className={"live-bets-table"}>
        <thead className="thead-baby">
          <tr className="table-header">
            <th className="header-item">Round</th>
            <th className="header-item">Multiplier</th>
            <th className="header-item">Hash</th>
          </tr>
        </thead>

        <div className="scrollable-content">
          {gameHistory?.map((game, index) => (
            <div className="gameHistory" key={index}>
              <div
                className={`gameHistory-container ${
                  index % 2 === 0 ? "first-bg" : "second-bg"
                }`}
              >
                #{game.round}{" "}
                <span
                  style={{
                    color: game.crash >= 1.8 ? "#59e659" : "rgb(189, 189, 189)",
                    marginLeft: "120px"
                  }}
                >
                  @{Number(game?.crash).toFixed(2)}x
                </span>
                <input
                  type="text"
                  className={`item-hash with-margin ${
                    index % 2 === 0 ? "first-bg" : "second-bg"
                  }`}
                  value={game.hash}
                  readOnly
                />
              </div>
            </div>
          ))}
        </div>
      </table>
    </>
  );
};

export default GameHistory;
