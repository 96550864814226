import React from 'react';
import {ConfigProvider} from "antd";

const AppThemeProvider = ({children}) => {

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#00b96b',
                },
                components: {
                    Modal: {
                        contentBg: "#303048",
                        headerBg: "#303048",
                        titleFontSize: 26,
                        titleColor: "#fff",
                        colorText: "#fff"
                    },
                    Input: {
                        activeBg: "#181928",
                        colorBgContainer: "#181928",
                        colorText: "#fff",
                        colorTextPlaceholder: "grey",
                        activeBorderColor: "#00b96b",
                        hoverBorderColor: "#00b96b",
                        paddingBlock: "5px",
                        fontSize: "20px",
                        colorIconHover: "#00b96b",
                        colorIcon: "#fff"
                    },
                    Button: {
                        defaultBg: "#181928",
                        colorText: "#fff",
                    },
                    Select: {
                        optionSelectedColor: "#fff",
                        colorText: "#fff",
                        optionActiveBg: "#303048",
                        optionSelectedBg: "orange",
                        selectorBg: "#181928",
                        clearBg: "#181928",
                        colorBorder: "#181928",
                        activeBg: "#181928",
                        optionPadding: 10,
                        colorTextPlaceholder: "grey"
                    },
                    InputNumber: {
                        colorBorder: "#181928",
                        handleBorderColor: "#181928",
                        handleHoverColor: "#181928",
                        activeBorderColor: "#181928",
                        activeBg: "#181928",
                        colorBgContainer: "#181928",
                        colorText: "#fff",
                        fontSize: '16px',
                        paddingBlock: "4px",
                        colorTextPlaceholder: "grey",
                        colorBgTextHover: "#fff",
                    },
                    Form: {
                        labelColor: "#fff"
                    }
                }
            }}
        >
            {children}
        </ConfigProvider>
    );
};

export default AppThemeProvider;