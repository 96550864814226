import React from 'react';
import {useSelector} from "react-redux";
import {betSelector} from "../../redux_ver2/selector/bet-history.selector";

const UserBets = () => {

    const history = useSelector(betSelector);

    return (
        <div className='dropdown-transactions'>
            <div>
                <table>
                    <thead className='dropdown-transactions-table-thead'>
                    <tr>
                        <th className='transactions-phone-version'>Time</th>
                        <th>Amount</th>
                        <th>Crypto</th>
                        <th>Profit</th>
                        <th style={{paddingRight: "0"}}>Cashout</th>
                        <th className='transactions-phone-version'>Round</th>
                        <th className='transactions-phone-version'>Crash</th>
                        <th className='transactions-phone-version'>Hash</th>
                    </tr>
                    </thead>

                    {
                        history.length === 0 ?
                            <p style={{padding: "8px"}}>Your bets history is empty</p> :
                            <tbody>
                            {
                                history?.map((item, index) =>
                                    <tr key={index}>
                                        <td className='transactions-phone-version'>{item?.time}</td>
                                        <td>{item?.amount}</td>
                                        <td>{item?.crypto}</td>
                                        <td style={{color: item?.profit > 0 ? "green" : "red"}}>{item?.profit}</td>
                                        <td>{item?.cashOut}</td>
                                        <td className='transactions-phone-version'>{item?.game_info.round}</td>
                                        <td className='transactions-phone-version' style={{color: item?.game_info.crash > 1.98 ? "green" : "red"}}>@{item?.game_info.crash}</td>
                                        <td className='transactions-phone-version'>
                                            <input
                                                type="text"
                                                className={`item-hash-mybets`}
                                                value={item?.game_info.hash}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                    }
                    <tbody>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UserBets;