import {LOG_IN, LOGOUT, REDEEM, REFRESH, REGISTER} from "../actions/auth.actions";
import {RESET_PASSWORD_BY_LINK} from "../actions/security.actions";

const initialState = {
    isAuth: false,
    user: {},
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case LOG_IN:
            return {
                isAuth: true,
                user: action.payload.user
            }

        case REGISTER:
            return {
                isAuth: true,
                user: action.payload.user
            }

        case LOGOUT:
            localStorage.removeItem("crypto");
            return {
                user: {},
                isAuth: false,
            }

        case REFRESH:
            return {
                isAuth: true,
                user: action.payload.user
            }

        case RESET_PASSWORD_BY_LINK:
            return {
                isAuth: false,
                user: {}
            }

        case REDEEM:
            return {
                ...state,
                user: action.payload.user,
            }

        default:
            return state;
    }
}