import React from 'react';
import {Helmet} from "react-helmet";
import Navbar from "../components/Navbar/Navbar";
import MobileNavbar from "../components/Mobile/MobileNavbar";
import Leaderboard from "../components/Leaderboard";
import Footer from "../components/Footer";
import GameWrapper from "../components/Game/GameWrapper";

const AppLayout = () => {

    return (
        <>
            <Helmet>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />
            </Helmet>

            <Navbar/>
            <MobileNavbar/>

            <GameWrapper/>

            <Leaderboard/>
            <Footer/>
        </>
    );
};

export default AppLayout;