import React from 'react';

const MobileLeaderboard = () => {
    return (
        <div>
            <p className="common-text">Top 10 Players Who Achieved the Highest Multiplier</p><br /><br />
            <table className="mobile-footer-table">
                <tr className="mobile-footer-table-tr">
                    <th className="mobile-footer-table-th">Rank</th>
                    <th className="mobile-footer-table-th">Name</th>
                    <th className="mobile-footer-table-th">Score</th>
                </tr>
                <tr className="mobile-footer-table-tr">
                    <td className="mobile-footer-table-td">1</td>
                    <td className="mobile-footer-table-td">Hazol</td>
                    <td className="mobile-footer-table-td">412.06x</td>
                </tr>
                <tr className="mobile-footer-table-tr">
                    <td className="mobile-footer-table-td">2</td>
                    <td className="mobile-footer-table-td">BWimer</td>
                    <td className="mobile-footer-table-td">339.01x</td>
                </tr>
                <tr className="mobile-footer-table-tr">
                    <td className="mobile-footer-table-td">3</td>
                    <td className="mobile-footer-table-td">lipski5</td>
                    <td className="mobile-footer-table-td">313.03x</td>
                </tr>
                <tr className="mobile-footer-table-tr">
                    <td className="mobile-footer-table-td">4</td>
                    <td className="mobile-footer-table-td">John</td>
                    <td className="mobile-footer-table-td">253.63x</td>
                </tr>
                <tr className="mobile-footer-table-tr">
                    <td className="mobile-footer-table-td">5</td>
                    <td className="mobile-footer-table-td">IQ500</td>
                    <td className="mobile-footer-table-td">120.10x</td>
                </tr>
                <tr className="mobile-footer-table-tr">
                    <td className="mobile-footer-table-td">6</td>
                    <td className="mobile-footer-table-td">THECREW</td>
                    <td className="mobile-footer-table-td">26.07x</td>
                </tr>
                <tr className="mobile-footer-table-tr">
                    <td className="mobile-footer-table-td">7</td>
                    <td className="mobile-footer-table-td">fein</td>
                    <td className="mobile-footer-table-td">25.95x</td>
                </tr>
                <tr className="mobile-footer-table-tr">
                    <td className="mobile-footer-table-td">8</td>
                    <td className="mobile-footer-table-td">realscott</td>
                    <td className="mobile-footer-table-td">15.05x</td>
                </tr>
                <tr className="mobile-footer-table-tr">
                    <td className="mobile-footer-table-td">9</td>
                    <td className="mobile-footer-table-td">bzar52</td>
                    <td className="mobile-footer-table-td">14.05x</td>
                </tr>
                <tr className="mobile-footer-table-tr">
                    <td className="mobile-footer-table-td">10</td>
                    <td className="mobile-footer-table-td">Coin99</td>
                    <td className="mobile-footer-table-td">10.50x</td>
                </tr>
            </table>
        </div>
    );
};

export default MobileLeaderboard;
