import React from 'react';
import ModalPage from "../../pages/ModalPage";
import ForgotPassword from "./ForgotPassword";

const ForgotPasswordWrapper = () => {
    return (
        <ModalPage
            component={<ForgotPassword/>}
            title={"Password Recover"}
        />
    );
};

export default ForgotPasswordWrapper;