import React, {useEffect, useState} from 'react';
import {Button, ConfigProvider, Divider, Form, Input, InputNumber} from "antd";
import {useNavigate} from "react-router-dom";
import {PATH} from "../router/PATH";
import socket from "../socket";

const VerificationTool = () => {

    const navigate = useNavigate();

    const [hashClone, setHashClone] = useState("");
    const [hash, setHash] = useState("");
    const [numbers, setNumbers] = useState(0);

    const [crashPoint, setCrashPoint] = useState(0);

    const handleHashChange = e => setHash(e.target.value);
    const handleNumberChange = e => setNumbers(e);

    const handleNavigateToGame = () => navigate(PATH.GAME);

    const onVerifyFinish = () => {
        socket.emit("game:verify_crash", hash);
        setHashClone(hash);
    }

    useEffect(() => {
        socket.on("game:hash_verified", crashNumber => {
            setCrashPoint(crashNumber);
        });
    }, []);

    return (
        <div className={"container verification-container"}>
            <h1 style={{paddingTop: "50px"}}>SpinSways - Game Verification Tool</h1>

            <Divider style={{border: "1px solid grey"}}/>

            <ConfigProvider
                theme={{
                    components: {
                        InputNumber: {
                            colorBorder: "white",
                            colorBgContainerDisabled: "#252539",
                            colorTextDisabled: "grey",
                            handleBg: "white",
                            handleVisible: true,
                        }
                    }
                }}
            >
                <Form
                    style={{marginTop: "20px"}}
                    onFinish={onVerifyFinish}
                >
                    <Form.Item
                        name={"hash"}
                    >
                        <Input
                            style={{width: "100%"}}
                            placeholder={"Enter hash"}
                            value={hash}
                            onChange={handleHashChange}
                        />
                    </Form.Item>

                    <Form.Item
                        name={"numbers_of_rounds"}
                    >
                        <InputNumber
                            size={"large"}
                            onChange={handleNumberChange}
                            value={numbers}
                            style={{width: "100%"}}
                            disabled={true}
                            placeholder={"Number of games to verify"}
                        />
                    </Form.Item>

                    <Button htmlType={"submit"} type={"primary"}>Verify</Button>

                    <Button
                        style={{marginLeft: "10px"}}
                        onClick={handleNavigateToGame}
                    >
                        Return to game page
                    </Button>
                </Form>
            </ConfigProvider>


            <Divider style={{border: "1px solid grey"}}/>

            <div className='dropdown-transactions' style={{margin: "30px 0 0 0"}}>
                <table>
                    <thead className='dropdown-transactions-table-thead'>
                    <tr>
                        <th>Game's hash</th>
                        <th>Crashed</th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        crashPoint > 0 ?
                            <tr>
                                <td>{hashClone}</td>
                                <td style={{
                                    color: crashPoint >= 2 ? "green" : "red",
                                    fontWeight: "bold"
                                }}>@{crashPoint}</td>
                            </tr> :
                            <tr></tr>
                    }
                    </tbody>
                </table>

            </div>
        </div>
    );
};

export default VerificationTool;