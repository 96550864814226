export const PATH = {

    HOME: "/",
    GAME: "/game",
    RESET_PASSWORD: "/reset-password/:refreshPasswordLink",
    FORGOT_PASSWORD: "/forgot-password",

    FAIRNESS: {
        FAIRNESS_VERIFICATION: "/fairness-verification",
        FAIRNESS_INFO: "/provable-fairness"
    },

    AUTH: {
       LOG_IN: "/log-in",
       SIGN_IN: "/sign-in"
    },

    LUCKY_WHEEL: "/lucky-wheel",
    SUPER_ROUNDS: "/super-round",
    CHALLENGES: "/challenges",

    DROPDOWN: {
        INFO: "/info",
        BETS: "/bets",

        SECURITY: {
            EMAIL: "/security/email",
            PASSWORD: "/security/password"
        },

        WALLET: {
            DEPOSIT: "/wallet/deposit",
            WITHDRAW: "/wallet/withdraw",
        },

        TRANSACTIONS: {
            BALANCES: "/transactions/balances",
            DEPOSITS: "/transactions/deposits",
            WITHDRAWS: "/transactions/withdraws"
        }
    },

    QR_CODE_PAYMENT: "/payment/:address",
    paymentAddress: (address) => `/payment/${address}`,

    SUPPORT: "/support",
    LEADERBOARD: "/leaderboard",
}
