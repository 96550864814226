import React from 'react';
import ModalPage from "../../pages/ModalPage";
import QRPayment from "./QR-Payment";

const QrPaymentWrapper = () => {
    return (
        <ModalPage
            component={<QRPayment/>}
            title={"Complete Deposit"}
        />
    );
};

export default QrPaymentWrapper;