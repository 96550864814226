import React, {useEffect, useState} from 'react';
import {Button, Input, Popover} from "antd";
import socket from "../../socket";
import {useSelector} from "react-redux";
import {userDataSelector} from "../../redux_ver2/selector/user.selector";

const WithdrawItem = ({key, amount, crypto, status, address, time, email, networkTransaction, _id, failMessage, withdrawId}) => {

    const user = useSelector(userDataSelector);


    const [showInput, setShowInput] = useState(false);
    const [requestFinished, setRequestFinished] = useState(true);

    const cancelClick = () => {
        if (requestFinished) {
            socket.emit("bettor:withdraw_cancel", {
                userId: user.id,
                email: user.email,
                amount: amount,
                withdrawId: withdrawId,
                crypto: crypto,
            });
            setRequestFinished(false);
        }
    }

    useEffect(() => {
        socket.on("bettor:withdraw_finished", (req) => setRequestFinished(req))
    }, []);

    return (
        <tr key={key}>
            <td>{amount}</td>
            <td>{crypto}</td>
            <td
                style={{
                    color:
                        (status === "REJECTED" && "red") ||
                        (status === "SUCCESS" && "green") ||
                        (status === "CANCELED" && "grey") ||
                        (status === "PENDING" && "blue") ||
                        "inherit",
                    fontWeight: "bold",
                    fontSize: "20px"
                }}
            >{status}</td>

            <td>
                {address?.length > 10 ? (
                    // Render an input element if the address is longer than 10 characters
                    showInput ? (
                        <Input
                            value={address}
                            readOnly
                            style={{
                                fontSize: "12px"
                            }}
                            onClick={() => {
                                // Handle input click event if needed
                            }}
                        />
                    ) : (
                        // Display the first 10 characters and a "Show More" button
                        <>
                            {address?.substr(0, 10)}...
                            <Button
                                type="link"
                                style={{
                                    color: "#6565C6"
                                }}
                                onClick={() => setShowInput(true)}
                            >
                                Show Address
                            </Button>
                        </>
                    )
                ) : (
                    // Display the address as is if it's 10 characters or less
                    address
                )}
            </td>

            <td>{time}</td>
            <td>{email}</td>
            <td>{
                status === "PENDING" ?
                    <Button style={{width: "100%"}} onClick={() => cancelClick()} danger disabled={!requestFinished}>Cancel</Button> :

                    status === "SUCCESS" ?
                        <Popover content={<p>{networkTransaction}</p>} title={`${amount} ${crypto}. Transaction:`}>
                            <Button style={{width: "100%"}} type={"primary"}>Check transaction in network</Button>
                        </Popover> :

                        status === "REJECTED" &&
                        <Popover content={<p>{failMessage}</p>} title={`${amount} ${crypto} rejection reason:`}>
                            <Button style={{width: "100%"}} type={"default"}>Check reason</Button>
                        </Popover>
            }</td>
        </tr>
    );
};

export default WithdrawItem;