import {LOG_IN, LOGOUT, REFRESH, REGISTER} from "../actions/auth.actions";
import {RESET_PASSWORD_BY_LINK} from "../actions/security.actions";
import {
    BALANCES_UPDATE,
    DEPOSIT_V2,
    WITHDRAW_CANCEL_SOCKET,
    WITHDRAW_REQUEST,
} from "../actions/wallet.actions";

const initialState = {

    customer_id: "",

    deposits: [],

    withdraws: [],

    balances: [],

    depositsWaiting: [],

    withdrawWaiting: [],

    manualDeposit: [],

}

export const walletReducer = (state = initialState, action) => {

    switch (action.type) {

        case LOG_IN:
            return {
                deposits: action.payload.wallet.deposits,
                balances: action.payload.wallet.balances,
                withdraws: action.payload.wallet.withdraws,
                depositsWaiting: action.payload.wallet.waiting,
                customer_id: action.payload.wallet.customer_id,
            }

        case REGISTER:
            return {
                deposits: action.payload.wallet.deposits,
                balances: action.payload.wallet.balances,
                withdraws: action.payload.wallet.withdraws,
                depositsWaiting: action.payload.wallet.waiting,
                customer_id: action.payload.wallet.customer_id,
            }

        case LOGOUT:
            return {
                deposits: [],
                balances: [],
                withdraws: [],
                depositsWaiting: [],
                manualDeposit: [],
                customer_id: "",
            }

        case REFRESH:
            return {
                deposits: action.payload.wallet.deposits,
                balances: action.payload.wallet.balances,
                withdraws: action.payload.wallet.withdraws,
                depositsWaiting: action.payload.wallet.waiting,
                withdrawWaiting: action.payload.wallet.waitingWithdraws,
                manualDeposit: action.payload.wallet.manualDeposit,
                customer_id: action.payload.wallet.customer_id,
            }

        case RESET_PASSWORD_BY_LINK:
            return {
                deposits: [],
                withdraws: [],
                balances: [],
                depositsWaiting: [],
                customer_id: "",
            }
        case BALANCES_UPDATE:
            return {
                ...state,
                balances: action.payload.balances
            }

        case WITHDRAW_CANCEL_SOCKET:
            return {
                ...state,
                withdraws: action.payload.withdraws
            }

        case DEPOSIT_V2:
            return {
                ...state,
                deposits: action.payload.deposits,
                depositsWaiting: action.payload.waiting,
            }

        case WITHDRAW_REQUEST: {
            return {
                ...state,
                withdraws: action.payload.withdraws
            }
        }

        default:
            return state;

    }

}