import React from 'react';
import ModalPage from "../../pages/ModalPage";
import Security from "./Security";

const SecurityWrapper = () => {
    return (
        <ModalPage
            component={<Security/>}
            title={"Security"}
        />
    );
};

export default SecurityWrapper;