import React, {useState} from 'react';
import {useSelector} from "react-redux";
import "./security.css";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import {Divider} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {PATH} from "../../router/PATH";
import {userDataSelector} from "../../redux_ver2/selector/user.selector";

const Security = () => {

    const user = useSelector(userDataSelector);
    const navigate = useNavigate();
    const location = useLocation();

    const [activeTab, setActiveTab] = useState(location.pathname);

    const handleTabChange = (filter) => setActiveTab(filter);

    return (
        <div className='dropdown-transactions'>

            <h2 className={"security-title"}>Your current email address is {user?.email}</h2>


            <div className='dropdown-transactions-btns' style={{marginTop: "20px"}}>
                <div className='dropdown-transactions-btns-beautify'>
                    <button
                        onClick={() => {
                            handleTabChange(PATH.DROPDOWN.SECURITY.EMAIL);

                            navigate(PATH.DROPDOWN.SECURITY.EMAIL, {
                                state: {background: location?.state?.background}
                            })
                        }}
                        className="dropdown-transactions-btns-all"
                        style={{background: activeTab === PATH.DROPDOWN.SECURITY.EMAIL ? "#46465e" : "initial"}}
                    >
                        Change email
                    </button>
                    <button
                        onClick={() => {
                            handleTabChange(PATH.DROPDOWN.SECURITY.PASSWORD);

                            navigate(PATH.DROPDOWN.SECURITY.PASSWORD, {
                                state: {background: location?.state?.background}
                            })
                        }}
                        className="dropdown-transactions-btns-deposits"
                        style={{background: activeTab === PATH.DROPDOWN.SECURITY.PASSWORD ? "#46465e" : "initial"}}
                    >
                        Change password
                    </button>
                </div>
            </div>

            <div style={{padding: "15px 7px 0 7px"}}>

                {/* <p>You can user it to change your password in case if you forget it or set new email, within
                    confirmation mail</p> */}


                <Divider style={{border: "1px solid grey"}}/>

                <div>
                    {activeTab === PATH.DROPDOWN.SECURITY.EMAIL && <ChangeEmail/>}
                    {activeTab === PATH.DROPDOWN.SECURITY.PASSWORD && <ChangePassword/>}
                </div>
            </div>

            <Divider style={{border: "1px solid grey"}}/>

            <p
                style={{textAlign: "center"}}
                className={"auth-footer-link"}
                onClick={() => {
                    navigate(PATH.FORGOT_PASSWORD, {
                        state: {background: location?.state?.background}
                    })
                }}
            >
                Forgot Password?
            </p>

        </div>
    );
};

export default Security;