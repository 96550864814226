import {TOGGLE_LOADING} from "./loading.actions";
import {FEEDBACK} from "./feedback.actions";
import {changeEmail, changePassword, forgotPassword, resetPassword} from "../../service_ver2/security.service";

export const RESET_PASSWORD_BY_LINK = "RESET_PASSWORD_BY_LINK";

export const changePasswordAction = (userId, oldPw, newPw) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: true
            }
        });

        const response = await changePassword(userId, oldPw, newPw);

        dispatch({
            type: FEEDBACK,
            payload: {
                message: response.data.message,
                isError: false
            }
        })

    } catch (e) {
        dispatch({
            type: FEEDBACK,
            payload: {
                isError: true,
                message: e.response.data.message
            }
        });
    } finally {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: false
            }
        });
    }
}

export const changeEmailAction = (oldEmail, newEmail, password) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: true
            }
        });

        const response = await changeEmail(oldEmail, newEmail, password);

        dispatch({
            type: FEEDBACK,
            payload: {
                message: response.data.message,
                isError: false
            }
        })

    } catch (e) {
        dispatch({
            type: FEEDBACK,
            payload: {
                isError: true,
                message: e.response.data.message
            }
        });
    } finally {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: false
            }
        });
    }
}

export const forgotPasswordAction = (email) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: true
            }
        });

        const response = await forgotPassword(email);

        dispatch({
            type: FEEDBACK,
            payload: {
                message: response.data.message,
                isError: false
            }
        })

    } catch (e) {
        dispatch({
            type: FEEDBACK,
            payload: {
                isError: true,
                message: e.response.data.message
            }
        });
    } finally {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: false
            }
        });
    }
}

export const resetPasswordAction = (resetPasswordLink, newPassword) => async dispatch => {

    try {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: true
            }
        });

        const response = await resetPassword(resetPasswordLink, newPassword);

        dispatch({
            type: RESET_PASSWORD_BY_LINK,
            payload: {}
        });
        localStorage.removeItem("token");

        dispatch({
            type: FEEDBACK,
            payload: {
                message: response.data.message,
                isError: false
            }
        })

    } catch (e) {
        dispatch({
            type: FEEDBACK,
            payload: {
                isError: true,
                message: e.response.data.message
            }
        });
    } finally {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: false
            }
        });
    }

}