export const FEEDBACK = "FEEDBACK";
export const FEEDBACK_CLEAR = "FEEDBACK_CLEAR";
export const INCREASE_MESSAGES_COUNT = "INCREASE_MESSAGES_COUNT";
export const DECREASE_MESSAGES_COUNT = "DECREASE_MESSAGES_COUNT";

export const listenSocketFeedbackAction = (isError, message) => async dispatch => {
    dispatch({
        type: FEEDBACK,
        payload: {
            isError: isError,
            message: message
        }
    })
}

export const clearFeedbackAction = () => async dispatch => {
    dispatch({
        type: FEEDBACK_CLEAR,
        payload: {}
    });
}

export const increaseMessagesCount = () => async dispatch => {
    dispatch({
        type: INCREASE_MESSAGES_COUNT,
        payload: {}
    })
}

export const decreaseMessagesCount = () => async dispatch => {
    dispatch({
        type: DECREASE_MESSAGES_COUNT,
        payload: {}
    })
}

export const errorFeedback = (data) => async dispatch => {
    dispatch({
        type: FEEDBACK,
        payload: {
            isError: true,
            message: data?.message?.message
        }
    })
}