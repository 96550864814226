import React from 'react'
import bitcoin from '../assets/images/bitcoin.png';
import litecoin from '../assets/images/litecoin.png';
import dogecoin from '../assets/images/dogecoin.png';
import tether from '../assets/images/tether.png';
import tron from '../assets/images/tron.png';
import ripple from '../assets/images/ripple.png';
import ethereum from '../assets/images/ethereum.png';
import logo from '../assets/images/logo.png';
import {useLocation, useNavigate} from "react-router-dom";
import {PATH} from "../router/PATH";

const Footer = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const smoothScrollToTop = (event) => {
        event.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <footer className={"footer"}>
            <div className='container'>
                <div className='footer-info'>
                    <div className='innovations footer-info-div'>
                        <p className='footer-table'>Innovations</p><br/>
                        <a className='footer-links' onClick={smoothScrollToTop}>Super Rounds</a><br/>
                        <a className='footer-links' onClick={smoothScrollToTop}>Lucky Wheel</a><br/>
                        <a className='footer-links' onClick={smoothScrollToTop}>Challenges</a><br/>
                    </div>
                    <div className='community footer-info-div'>
                        <p className='footer-table'>Community</p><br/>
                        <a href='https://twitter.com/SpinSways' target='_blank' className='footer-links'>Twitter</a><br/>
                        <a href='https://t.me/SpinSways' target='_blank' className='footer-links'>Telegram</a><br/>
                        <a href='https://medium.com/@spinsways' target='_blank' className='footer-links'>Medium</a><br/>
                    </div>
                    <div className='footer-info-div'>
                        <p className='footer-table'>Support</p><br/>
                        <a href='https://spinsways.gitbook.io/about/faq' target='_blank' className='footer-links'>FAQ</a><br/>
                        <a href='https://spinsways.gitbook.io/about/support' target='_blank' className='footer-links'>Contact
                            Us</a><br/>
                        <a href='https://spinsways.gitbook.io/about/support' target='_blank' className='footer-links'>Leave
                            Feedback</a><br/>
                        <a href='https://www.begambleaware.org/' target='_blank' className='footer-links'>Gamble
                            Aware</a><br/>
                        <a href='https://spinsways.gitbook.io/about/responsible-gambling' target='_blank'
                           className='footer-links'>Responsible Gambling</a><br/>
                    </div>
                    <div className='about-us footer-info-div'>
                        <p className='footer-table'>About Us</p><br/>
                        <a href='https://twitter.com/SpinSways' className='footer-links' target="_blank">News</a><br/>
                        <p style={{display: "inline-block"}} className='footer-links'
                            onClick={() => {
                                navigate(PATH.FAIRNESS.FAIRNESS_INFO, {
                                   state: { background: location }
                                });
                            }}
                        >Fairness</p><br/>
                        <a href='https://spinsways.gitbook.io/about/policies/aml-policy' className='footer-links'
                           target="_blank">AML Policy</a><br/>
                        <a href='https://spinsways.gitbook.io/about/policies/privacy-policy' className='footer-links'
                           target="_blank">Privacy Policy</a><br/>
                        <a href='https://spinsways.gitbook.io/about/policies/terms-of-service' className='footer-links'
                           target="_blank">Terms of Service</a><br/>
                    </div>
                    <div className='footer-info-div'>
                        <p className='footer-table'>Promo</p><br/>
                        <a href='mailto:support@spinsways.com' className='footer-links' target="_blank">VIP
                            Club</a><br/>
                        <a href='mailto:support@spinsways.com' className='footer-links' target="_blank">Affiliate
                            Program</a><br/>
                        <a href='mailto:support@spinsways.com' className='footer-links' target="_blank">Redeem a
                            Code</a><br/>
                    </div>
                </div>

                <hr/>

                <div className='logo-wrapper'>
                    <div className='crypto-item'>
                        <a
                        onClick={() => {
                            navigate(PATH.DROPDOWN.WALLET.DEPOSIT, {
                                state: {background: location}
                            });
                        }}
                        >
                            <img className='footer-crypto' src={bitcoin} alt="Bitcoin"/>
                        </a>
                        <p>Bitcoin</p>
                    </div>
                    <div className='crypto-item'>
                        <a
                        onClick={() => {
                            navigate(PATH.DROPDOWN.WALLET.DEPOSIT, {
                                state: {background: location}
                            });
                        }}
                        >
                            <img className='footer-crypto' src={ethereum} alt="Ethereum"/>
                        </a>
                        <p>Ethereum</p>
                    </div>
                    <div className='crypto-item'>
                        <a
                        onClick={() => {
                            navigate(PATH.DROPDOWN.WALLET.DEPOSIT, {
                                state: {background: location}
                            });
                        }}
                        >
                            <img className='footer-crypto' src={tether} alt="Tether"/>
                        </a>
                        <p>Tether</p>
                    </div>
                    <div className='crypto-item'>
                        <a
                        onClick={() => {
                            navigate(PATH.DROPDOWN.WALLET.DEPOSIT, {
                                state: {background: location}
                            });
                        }}
                        >
                            <img className='footer-crypto' src={litecoin} alt="Litecoin"/>
                        </a>
                        <p>Litecoin</p>
                    </div>
                    <div className='crypto-item'>
                        <a
                        onClick={() => {
                            navigate(PATH.DROPDOWN.WALLET.DEPOSIT, {
                                state: {background: location}
                            });
                        }}
                        >
                            <img className='footer-crypto' src={dogecoin} alt="Dogecoin"/>
                        </a>
                        <p>Dogecoin</p>
                    </div>
                    <div className='crypto-item'>
                        <a
                        onClick={() => {
                            navigate(PATH.DROPDOWN.WALLET.DEPOSIT, {
                                state: {background: location}
                            });
                        }}
                        >
                            <img className='footer-crypto' src={tron} alt="Tron"/>
                        </a>
                        <p>Tron</p>
                    </div>
                    <div className='crypto-item'>
                        <a
                        onClick={() => {
                            navigate(PATH.DROPDOWN.WALLET.DEPOSIT, {
                                state: {background: location}
                            });
                        }}
                        >
                            <img className='footer-crypto' src={ripple} alt="Ripple"/>
                        </a>
                        <p>Ripple</p>
                    </div>
                </div>

                <hr/>

                <div className='legal'>
                    <span className='ageplus'>18+</span>
                    <img className='logo-footer' src={logo}/>
                    <span className='responsible'>Responsible<br/>Gambling</span>
                </div>

            </div>
        </footer>
    );
};

export default Footer;
