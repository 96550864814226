import {
    login,
    registration,
    logout,
    refresh,
    resendActivateMail,
    redeemActivate
} from "../../service_ver2/auth.service";
import {TOGGLE_LOADING, TOGGLE_PRELOADER} from "./loading.actions";
import {FEEDBACK} from "./feedback.actions";
import {saveRefreshWithExpiredDate} from "../../utils/tokenUtils";
import socket from "../../socket";

export const LOG_IN = "LOG_IN";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";
export const REFRESH = "REFRESH";
export const REDEEM = "REDEEM";


export const redeemActivateAction = (userId, username, code) => async dispatch => {
    try {
        dispatch(
            {
                type: TOGGLE_LOADING,
                payload: {
                    loading: true
                }
            }
        );

        const response = await redeemActivate(userId, username, code);

        dispatch({
            type: REDEEM,
            payload: {
                user: response?.data?.user
            }
        });

        dispatch({
            type: FEEDBACK,
            payload: {
                isError: false,
                message: response?.data?.message?.message,
            }
        });

    } catch (e) {
        dispatch({
            type: FEEDBACK,
            payload: {
                isError: true,
                message: e?.response?.data?.message
            }
        })
    } finally {
        dispatch(
            {
                type: TOGGLE_LOADING,
                payload: {
                    loading: false
                }
            }
        );
    }
}

export const loginAction = (log, pw) => async dispatch => {
    try {

        dispatch(
            {
                type: TOGGLE_LOADING,
                payload: {
                    loading: true
                }
            }
        );

        const response = await login(log, pw);
        localStorage.setItem("token", response?.data?.token.accessToken);
        saveRefreshWithExpiredDate(response.data?.token.refreshToken);

        dispatch(
            {
                type: LOG_IN,
                payload: {
                    user: response.data.user,
                    wallet: response.data.wallet,
                    bets: response.data.betHistory,
                }
            }
        );

        dispatch(
            {
                type: FEEDBACK,
                payload: {
                    message: response?.data?.message?.message,
                    isError: false
                }
            }
        );


    } catch (e) {
        dispatch({
            type: FEEDBACK,
            payload: {
                isError: true,
                message: e?.response?.data?.message
            }
        })
    } finally {
        dispatch(
            {
                type: TOGGLE_LOADING,
                payload: {
                    loading: false
                }
            }
        );
    }
}

export const registerAction = (username, email, pw, redeem) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: true
            }
        });

        const response = await registration(username, email, pw, redeem);

        dispatch(
            {
                type: FEEDBACK,
                payload: {
                    message: response?.data?.message?.message,
                    isError: false
                }
            }
        )

        dispatch({
            type: REGISTER,
            payload: {
                user: response.data.user,
                wallet: response.data.wallet,
                bets: response.data.betsHistory,
            }
        });
        localStorage.setItem("token", response?.data?.token.accessToken);
        saveRefreshWithExpiredDate(response.data?.token.refreshToken);


    } catch (e) {
        dispatch({
            type: FEEDBACK,
            payload: {
                isError: true,
                message: e?.response?.data?.message
            }
        });
    } finally {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: false
            }
        });
    }
}

export const logoutAction = () => async dispatch => {

    try {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: true
            }
        });

        const response = await logout();
        localStorage.removeItem("token");
        localStorage.removeItem("rToken");
        localStorage.removeItem("crypto");

        dispatch({
            type: LOGOUT,
            payload: {}
        })

        dispatch({
            type: FEEDBACK,
            payload: {
                message: response.data.message,
                isError: false
            }
        });

    } catch (e) {
        dispatch({
            type: FEEDBACK,
            payload: {
                message: e?.response?.data?.message,
                isError: true
            }
        });
    } finally {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: false
            }
        })
    }

}

export const refreshAction = () => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_PRELOADER,
            payload: {
                preloader: true
            }
        });

        const response = await refresh();
        localStorage.setItem("token", response.data.token.accessToken);
        saveRefreshWithExpiredDate(response.data?.token.refreshToken);

        dispatch({
            type: REFRESH,
            payload: {
                user: response.data.user,
                wallet: response.data.wallet,
                bets: response.data.betHistory,
            }
        });

    } catch (e) {
        // console.log("refresh caught error ->", e);
    } finally {
        dispatch({
            type: TOGGLE_PRELOADER,
            payload: {
                preloader: false
            }
        })
    }
}

export const resendActivationMailAction = (email) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: true
            }
        });

        const response = await resendActivateMail(email);

        dispatch({
            type: FEEDBACK,
            payload: {
                isError: false,
                message: response.data.message
            }
        })

    } catch (e) {
        dispatch({
            type: FEEDBACK,
            payload: {
                isError: true,
                message: e.response.data.message
            }
        })
    } finally {
        dispatch({
            type: TOGGLE_LOADING,
            payload: {
                loading: false
            }
        });
    }

}