import React from 'react';
import {PATH} from "../../router/PATH";
import {useLocation, useNavigate} from "react-router-dom";

export const OFFER_TYPES = {
    DEPOSIT: "DEPOSIT",
    WITHDRAWS: "WITHDRAWS",
};

const Offer = ({type = OFFER_TYPES.DEPOSIT}) => {

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div style={{margin: "0 0 0 0"}}>
            <p className='display-none' style={{padding: "8px 8px 0 8px"}}>Your balance is empty</p>
            <p
                className={"auth-footer-link"}
                style={{display: "inline-block", padding: "0 8px 8px 8px"}}
                onClick={() => {
                    if (type === OFFER_TYPES.DEPOSIT) {
                        navigate(PATH.DROPDOWN.WALLET.DEPOSIT, {
                            state: {background: location?.state?.background}
                        });
                    } else if (type === OFFER_TYPES.WITHDRAWS) {
                        navigate(PATH.DROPDOWN.WALLET.WITHDRAW, {
                            state: {background: location?.state?.background}
                        });
                    }
                }}
            >{type === OFFER_TYPES.DEPOSIT ? "DEPOSIT HERE" : "WITHDRAW HERE"}</p>
        </div>
    );
};

export default Offer;