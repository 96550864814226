import React from 'react';
import ModalPage from "../../pages/ModalPage";
import UserBets from "./UserBets";

const UserBetsWrapper = () => {
    return (
        <ModalPage
            component={<UserBets/>}
            title={"Betting History"}
        />
    );
};

export default UserBetsWrapper;