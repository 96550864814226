import {TOGGLE_LOADING, TOGGLE_PRELOADER} from "../actions/loading.actions";

const initialState = {

    preloader: false,
    loading: false

}

export const loadingReducer = (state = initialState, action) => {

    switch (action.type) {

        case TOGGLE_LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }

        case TOGGLE_PRELOADER:
            return {
                ...state,
                preloader: action.payload.preloader
            }

        default:
            return state;

    }

}