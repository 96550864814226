import React, {useState} from 'react';
import DepositForm from "./DepositForm";
import WithdrawForm from "./WithdrawForm";
import {useLocation, useNavigate} from "react-router-dom";
import {PATH} from "../../router/PATH";

const Deposit = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [activeTab, setActiveTab] = useState(location.pathname);
    const handleTabChange = tab => setActiveTab(tab);

    return (
        <div className='dropdown-transactions'>
            <h2 className={"security-title"}>Deposits & Withdrawals</h2>
            <div className='dropdown-transactions-btns' style={{marginTop: "20px"}}>
                <div className='dropdown-transactions-btns-beautify'>
                    <button
                        onClick={() => {
                            handleTabChange(PATH.DROPDOWN.WALLET.DEPOSIT);

                            navigate(PATH.DROPDOWN.WALLET.DEPOSIT, {
                                state: {background: location?.state?.background}
                            });
                        }}
                        className="dropdown-transactions-btns-all"
                        style={{background: activeTab === PATH.DROPDOWN.WALLET.DEPOSIT ? "#46465e" : "initial"}}
                    >
                        Deposit
                    </button>
                    <button
                        onClick={() => {
                            handleTabChange(PATH.DROPDOWN.WALLET.WITHDRAW);

                            navigate(PATH.DROPDOWN.WALLET.WITHDRAW, {
                                state: {background: location?.state?.background}
                            });
                        }}
                        className="dropdown-transactions-btns-deposits"
                        style={{background: activeTab === PATH.DROPDOWN.WALLET.WITHDRAW ? "#46465e" : "initial"}}
                    >
                        Withdraw
                    </button>
                </div>
            </div>

            <div style={{padding: "15px 7px 0 7px"}}>
                <div>
                    {activeTab === PATH.DROPDOWN.WALLET.DEPOSIT && <DepositForm/>}
                    {activeTab === PATH.DROPDOWN.WALLET.WITHDRAW && <WithdrawForm/>}
                </div>
            </div>

        </div>
    );
};

export default Deposit;