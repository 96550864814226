import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import "../App.css";
import {Button, Form} from "antd";
import {passwordMaxLengthRule, passwordMinLengthRule, requiredRule} from "../utils/formRulesValidation";
import Password from "antd/es/input/Password";
import {useDispatch} from "react-redux";
import {resetPasswordAction} from "../redux_ver2/actions/security.actions";

const ResetPassword = () => {

    const dispatch = useDispatch();
    const {refreshPasswordLink} = useParams();

    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    const handlePasswordChange = e => setPassword(e.target.value);
    const handleRepeatPasswordChange = e => setRepeatPassword(e.target.value);


    const onFinish = () => dispatch(resetPasswordAction(refreshPasswordLink, password));

    return (
        <div
            className={"container"}
            style={{
                height: "100vh",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "0 10px"
            }}
        >
            <h2 className={"hero"}>Set a new password to your account</h2>

            <Form
                style={{width: "100%"}}
                autoComplete={"off"}
                onFinish={onFinish}
            >
                <Form.Item
                    name={"password"}
                    label={'New password'}
                    rules={[
                        requiredRule("Password is required"),
                        passwordMinLengthRule(),
                        passwordMaxLengthRule(),
                    ]}
                >
                    <Password
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </Form.Item>

                <Form.Item
                    name={"repeatPassword"}
                    dependencies={["password"]}
                    label={"Repeat new password"}
                    rules={[
                        requiredRule("Repeat new password"),
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("The new password that you entered do not match!"))
                            }
                        })
                    ]}
                >
                    <Password
                        onChange={handleRepeatPasswordChange}
                        value={repeatPassword}
                    />
                </Form.Item>

                <Button style={{width: "10%"}} type={"primary"} htmlType="submit">Confirm</Button>

            </Form>

        </div>
    );
};

export default ResetPassword;