import React, {createRef, useEffect, useState} from 'react';
import betSound from "../../assets/audio/launch.mp3";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    isAuthSelector,
    userDataSelector
} from "../../redux_ver2/selector/user.selector";
import socket from "../../socket";
import {ConfigProvider, Form, InputNumber} from "antd";
import SelectCrypto from "../SelectCryptoDropDown/SelectCrypto";
import {balancesSelector} from "../../redux_ver2/selector/wallet.selector";
import {bet, betNext, cancel, cashOut} from "../../redux_ver2/actions/socket.actions";
import {PATH} from "../../router/PATH";
import {exactValueUtil} from "../../utils/exactValueUtil";
import {getTimeZone} from "../../utils/getTimeZone";

const BetPanel = () => {

    const [form] = Form.useForm();

    const navigate = useNavigate();
    const location = useLocation();
    const audioRef = createRef();

    const isAuth = useSelector(isAuthSelector);
    const userData = useSelector(userDataSelector);
    const balances = useSelector(balancesSelector);

    const [multiplier, setMultiplier] = useState(1.00);

    const [gameState, setGameState] = useState(false);

    const [amount, setAmount] = useState(0);
    const [crypto, setCrypto] = useState("");
    const [fixedCashOut, setFixedCashOut] = useState(null);

    const [socketFixedClone, setSocketFixedClone] = useState(null);
    const [socketAmountClone, setSocketAmountClone] = useState(null);

    const [hasBet, setHasBet] = useState(false);
    const [hasNext, setHasNext] = useState(false);
    const [possibleCashOut, setPossibleCashOut] = useState(false);

    const [activeBalance, setBalance] = useState("");

    const [buttonText, setButtonText] = useState("Connecting...");

    // true - when request sent to socket until socket will respond
    const [actionPending, setActionPending] = useState(false);

    useEffect(() => {
        socket.on("game:multiplier", m => setMultiplier(m));
        socket.on("bettor:fixed_cash_out", fixed => setSocketFixedClone(fixed));

        socket.on("bettor:bet_amount", amount => {
            setAmount(amount);
            setSocketAmountClone(amount);
        });

        socket.on("bettor:bet_info", bet => {
            setAmount(bet.amount);
            setSocketAmountClone(bet.amount);
            setCrypto(bet.crypto);
            setFixedCashOut(bet.fixedCashOut);
        })
        socket.on("bettor:state", actions => {
            setPossibleCashOut(actions.hasCahOut);
            setHasBet(actions.hasBet);
            setHasNext(actions.hasNext);
        });

        socket.on("request:completed", () => setActionPending(false));

        socket.on("game:state", state => setGameState(state));
    }, []);

    useEffect(() => {
        socket.emit("bettor:refresh_state", gameState);
    }, [gameState]);

    useEffect(() => {
        if (localStorage.getItem("crypto") && balances.find(b => b.crypto === localStorage.getItem("crypto"))) {
            setBalance(balances?.filter(item => item.crypto === localStorage.getItem("crypto"))[0]?.amount);
            setCrypto(localStorage.getItem("crypto"));
        }
    }, [crypto, balances]);

    const handleAmountChange = e => setAmount(e);
    const handleFixedCashOutChange = e => setFixedCashOut(e);
    const handleCryptoChange = val => setCrypto(val);

    useEffect(() => {
        if (!isAuth) setCrypto("");
    }, [isAuth]);

    useEffect(() => {
        if (gameState && !hasBet && !hasNext) setButtonText("Bet (next round)");
        else if (isAuth && gameState && !hasBet && hasNext) setButtonText("Cancel");
        else if (!gameState && !hasBet && !hasNext) setButtonText("Bet");
        else if (isAuth && !gameState && hasBet && !hasNext) setButtonText("Wait round to start");
    }, [gameState, possibleCashOut, hasBet, hasNext, isAuth]);

    useEffect(() => {
        if (isAuth && gameState && possibleCashOut && hasBet && !hasNext) setButtonText(`Cash out ${exactValueUtil(socketAmountClone * multiplier).toFixed(socketAmountClone?.toString().length <= 4 ? 2 : socketAmountClone?.toString().length)}`)

        if (socketFixedClone != null && multiplier > (exactValueUtil(socketFixedClone - 0.01)) && possibleCashOut && hasBet) {
            cashOut(userData?.username, socketFixedClone, getTimeZone());
        }
    }, [multiplier]);


    return (
        <div>
            <ConfigProvider
                theme={{
                    token: {
                        colorIconHover: "#fff",
                        colorPrimary: "orange",
                    },
                    components: {
                        Select: {
                            colorBorder: "white",
                            colorBgContainerDisabled: "#252539",
                            colorTextDisabled: "grey",
                        },
                        InputNumber: {
                            colorBorder: "white",
                            colorBgContainerDisabled: "#252539",
                            colorTextDisabled: "grey",
                            handleBg: "white",
                            handleVisible: true
                        }
                    },
                }}
            >

                <Form
                    form={form}
                    style={{
                        display: "grid",
                    }}
                    className={"mobile-wrapper"}
                >

                    <button
                        className={"bet"}
                        type={"submit"}
                        disabled={isAuth ? (!gameState && hasBet && !hasNext) : false}
                        onClick={() => {
                            if (isAuth && !actionPending) {
                                if (gameState && !hasBet && !hasNext) {
                                    setSocketAmountClone(amount);
                                    betNext(userData?.username, amount, crypto, -amount, "-", fixedCashOut, getTimeZone());
                                    audioRef.current.play();
                                } else if (gameState && !hasBet && hasNext) {
                                    cancel(userData?.username);
                                } else if (!gameState && !hasBet && !hasNext) {
                                    setSocketAmountClone(amount);
                                    bet(userData?.username, amount, crypto, -amount, "-", fixedCashOut, getTimeZone());
                                    audioRef.current.play();
                                } else if (gameState && possibleCashOut && hasBet && !hasNext) {
                                    cashOut(userData?.username, multiplier, getTimeZone());
                                }
                                setActionPending(true);
                            } else {
                                navigate(PATH.AUTH.SIGN_IN, {
                                    state: {background: location}
                                });
                            }
                        }}
                    >
                        <span>{buttonText}</span>
                    </button>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "49% 49%",
                        gridColumnGap: "2%",
                        margin: "15px 0 0 0"
                    }}>
                        <Form.Item
                            name={"cashOut"}
                            style={{margin: 0}}
                        >
                            <p className={"mobile-betting-description"}>Cashout</p>
                            <InputNumber
                                style={{width: "100%"}}
                                id={"cashOut"}
                                min={1}
                                className="cashout-at"
                                placeholder="Cashout At (Example: 1.5)"
                                value={fixedCashOut}
                                onChange={handleFixedCashOutChange}
                            />
                        </Form.Item>

                        <Form.Item
                            name={"amount"}
                        >
                            <p className={"mobile-betting-description"}>Amount</p>
                            <InputNumber
                                id={"betAmount"}
                                style={{width: "100%"}}
                                placeholder="Bet Amount (Example: 0.5)"
                                className={"bet-amount"}
                                value={amount}
                                onChange={handleAmountChange}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item
                        style={{width: "100%"}}
                        name={"crypto"}
                    >
                        {/*<p className={"mobile-betting-description"}>Choose crypto</p>*/}
                        <SelectCrypto
                            handleOnChange={handleCryptoChange}
                        />
                    </Form.Item>


                    <p
                    style={{color: "white", fontWeight: "bolder", fontSize: "16px"}}>
                        Active balance:
                        {
                            !isAuth
                                ? " Empty..." :
                                (activeBalance !== undefined && crypto.length > 0) ?
                                    ` ${activeBalance} ${crypto}`
                                    : " No crypto selected"
                        }
                    </p>
                </Form>

            </ConfigProvider>

            <audio ref={audioRef} src={betSound}></audio>
        </div>
    );
};

export default BetPanel;