import React from 'react';
import ModalPage from "../../pages/ModalPage";
import Transactions from "./Transactions";

const TransactionsWrapper = () => {
    return (
        <ModalPage
            component={<Transactions/>}
            title={"Transactions"}
        />
    );
};

export default TransactionsWrapper;