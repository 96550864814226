import axios from "axios";
import $api, {API_URL} from "../api";

export const changePassword = async (userId, oldPw, newPw) => {

    return await $api.post("/change-password", {
        userId,
        oldPw,
        newPw
    });

}

export const changeEmail = async (oldEmail, newEmail, password) => {

    return await $api.post("/change-email", {
        oldEmail,
        newEmail,
        password
    });

}

export const forgotPassword = async email => {

    return await axios.post(`${API_URL}/forgot-password`, {
        email
    });

}

export const resetPassword = async (resetPasswordLink, newPassword) => {

    return await axios.post(`${API_URL}/reset-password`, {
        resetPasswordLink,
        newPassword
    });

}