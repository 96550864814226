import React, {useEffect, useState} from 'react';
import {Button, Form, InputNumber, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {subPartnerIdSelector} from "../../redux_ver2/selector/wallet.selector";
import {userDataSelector} from "../../redux_ver2/selector/user.selector";
import {depositAction} from "../../redux_ver2/actions/wallet.actions";
import {useLocation, useNavigate} from "react-router-dom";
import {PATH} from "../../router/PATH";
import {qrRedirectSelector, redirectLinkSelector} from "../../redux_ver2/selector/feedback.selector";
import {depositCrypto} from "../../utils/deposit-crypto";
import {requiredRule} from "../../utils/formRulesValidation";

const DepositForm = () => {

    const user = useSelector(userDataSelector);
    const customerId = useSelector(subPartnerIdSelector);

    const isRedirect = useSelector(qrRedirectSelector);
    const redirectLink = useSelector(redirectLinkSelector);

    const dispatch = useDispatch();
    const [crypto, setCrypto] = useState("");
    const [amount, setAmount] = useState(0);

    const navigate = useNavigate();
    const location = useLocation();

    const handleAmountChange = e => setAmount(e);
    const handleCryptoChange = c => setCrypto(c);


    useEffect(() => {
        if (isRedirect) navigate(PATH.paymentAddress(redirectLink), {
            state: {background: location?.state?.background}
        });
    }, [isRedirect, redirectLink]);

    const onFinish = () => dispatch(depositAction(crypto, customerId, amount, user?.id));

    return (
        <div>
            {/* <p>Your money will be 100% secured, because of using verified pay service NOW Payments. You can follow your
                transaction here: link</p> */}

            <Form
                onFinish={onFinish}
                style={{padding: "10px 0"}}
            >
                <Form.Item
                    rules={[
                        requiredRule("Choose crypto to deposit")
                    ]}
                    name={"crypto"}
                >
                    <Select
                        className={"select-crypto"}
                        dropdownStyle={{background: "#181928", zIndex: 10000000}}
                        style={{color: "#ffffff"}}

                        optionFilterProp="children"

                        onChange={handleCryptoChange}
                        placeholder="Select Crypto"
                        value={crypto}

                        options={
                            depositCrypto?.map((item) => {
                                return {
                                    value: item?.code,
                                    label: (
                                        (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={item?.icon} alt={item.label} style={{ marginRight: 8, width: '24px', height: '24px' }} />
                                                {item.label}
                                            </div>
                                        )
                                    )
                                }
                            })
                        }
                    />
                </Form.Item>

                <Form.Item
                    name={"amount"}
                    rules={[
                        requiredRule("Type amount")
                    ]}
                >
                    <InputNumber
                        min={0}
                        size={"large"}
                        style={{width: "100%", fontSize: "17px"}}
                        placeholder={"Enter amount in " + crypto}
                        onChange={handleAmountChange}
                        value={amount}
                    />
                </Form.Item>

                <Button htmlType={"submit"} type={"primary"}>Deposit</Button>

            </Form>

            <p
                className={"auth-footer-link"}
                style={{position: "relative", bottom: 0, left: 0, textAlign: "center"}}
                onClick={() => {
                    navigate(PATH.DROPDOWN.TRANSACTIONS.DEPOSITS, {
                        state: {background: location?.state?.background}
                    })
                }}
            >Check status of your transactions here</p>

        </div>
    );
};

export default DepositForm;
