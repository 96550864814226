import React, {useEffect} from 'react';
import "./App.css";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {BackgroundContext} from "./context/background.context";
import MainRoutes from "./router/MainRoutes";
import ModalRoutes from "./router/ModalRoutes";
import ToastConfigProviderWrapper from "./components/ToastCinfigProvider/ToastConfigProviderWrapper";
import 'react-toastify/dist/ReactToastify.css';
import {isAuthSelector, userDataSelector} from "./redux_ver2/selector/user.selector";
import socket from "./socket";
import {
    balancesSelector, manualDepositSelector, waitingDepositsSelector, waitingWithdrawsSelector,
    withdrawsSelector
} from "./redux_ver2/selector/wallet.selector";
import {betSelector} from "./redux_ver2/selector/bet-history.selector";
import {balancesUpdateAction, withdrawCancelAction} from "./redux_ver2/actions/wallet.actions";
import {betsHistoryUpdateAction} from "./redux_ver2/actions/bet-history.actions";
import {refreshAction} from "./redux_ver2/actions/auth.actions";
import {loadingSelector, preloaderSelector} from "./redux_ver2/selector/loading.selector";
import Loader from "./components/Loader/Loader";

const App = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const background = location.state?.background;
    const isAuth = useSelector(isAuthSelector);
    const isLoading = useSelector(loadingSelector);
    const preloader = useSelector(preloaderSelector);

    const user = useSelector(userDataSelector);
    const balances = useSelector(balancesSelector);
    const bets = useSelector(betSelector);
    const withdraws = useSelector(withdrawsSelector);
    const waitingDeposits = useSelector(waitingDepositsSelector);
    const manualDeposits = useSelector(manualDepositSelector);
    const waitingWithdraw = useSelector(waitingWithdrawsSelector);

    useEffect(() => {
        dispatch(refreshAction());
        // socket.emit("bettor:balance_verify");
        socket.on("bettor:balance_modify", balance => dispatch(balancesUpdateAction(balance)));
        socket.on("bettor:bets_history_modify", history => dispatch(betsHistoryUpdateAction(history)));
        socket.on("bettor:withdraw_cancel_response", data => dispatch(withdrawCancelAction(data)));
    }, []);

    useEffect(() => {
        if (isAuth) {
            socket.emit("bettor:id_connection", {
                id: user.id,
                username: user.username,
                userId: user.id
            });
            socket.emit("bettor:sync_data", {
                id: user.id,
                bets: bets,
                balances: balances,
                withdraws: withdraws,
                waitingDeposits: waitingDeposits,
                manualDeposits: manualDeposits,
                waitingWithdraws: waitingWithdraw,
                userId: user.id
            });
        }
    }, [isAuth]);

    return (
        <>
            {(isLoading && <Loader/>) || (preloader && <Loader/>)}

            <ToastConfigProviderWrapper/>
            <BackgroundContext.Provider value={{background}}>

                <MainRoutes authorized={isAuth} location={background || location}>
                </MainRoutes>

                <ModalRoutes authorized={isAuth}/>
            </BackgroundContext.Provider>
        </>
    );
};

export default App;