import React, {useState} from 'react';
import {Button, Form} from "antd";
import Password from "antd/es/input/Password";
import {passwordMaxLengthRule, passwordMinLengthRule, requiredRule} from "../../utils/formRulesValidation";
import {useDispatch, useSelector} from "react-redux";
import {userDataSelector} from "../../redux_ver2/selector/user.selector";
import {changePasswordAction} from "../../redux_ver2/actions/security.actions";

const ChangePassword = () => {

    const dispatch = useDispatch();

    const user = useSelector(userDataSelector);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    const handleOldPasswordChange = e => setOldPassword(e.target.value);
    const handleNewPasswordChange = e => setNewPassword(e.target.value);
    const handleRepeatPasswordChange = e => setRepeatPassword(e.target.value);


    const onFinish = () => dispatch(changePasswordAction(user?.id, oldPassword, newPassword));

    return (
        <>
            <h2 style={{marginBottom: "20px"}}>Change your password</h2>

            <Form
                onFinish={onFinish}
                autoComplete={"off"}
            >
                <Form.Item
                    name={"old password"}
                    label={"Old password"}
                    rules={[
                        requiredRule("Old password is required")
                    ]}
                >
                    <Password
                        value={oldPassword}
                        onChange={handleOldPasswordChange}
                    />
                </Form.Item>

                <Form.Item
                    name={"newPassword"}
                    label={"New password"}
                    rules={[
                        requiredRule("New password is required"),
                        passwordMinLengthRule(),
                        passwordMaxLengthRule()
                    ]}
                >
                    <Password
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                    />
                </Form.Item>

                <Form.Item
                    name={"repeatNewPassword"}
                    label={"Repeat new password"}
                    dependencies={["newPassword"]}
                    rules={[
                        requiredRule("Repeat new password is required"),
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("newPassword") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("Old and new passwords don't match"))
                            }
                        })
                    ]}
                >
                    <Password
                        value={repeatPassword}
                        onChange={handleRepeatPasswordChange}
                    />
                </Form.Item>

                <Button type={"primary"} htmlType={"submit"}>Submit</Button>
            </Form>
        </>
    );
};

export default ChangePassword;