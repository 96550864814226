import React from 'react';

const Leaderboard = () => {
    return (
        <section className="container" style={{padding: "0 10px"}}>
            <div className="leaderboard">
                <table className="mobile-footer-table">
                    <tr className="mobile-footer-table-tr">
                        <th className="mobile-footer-table-th">Leaderboard</th>
                        <th className="mobile-footer-table-th">Name</th>
                        <th className="mobile-footer-table-th">Multiplier</th>
                        <th className="mobile-footer-table-th">Profit</th>
                    </tr>
                    <tr className="mobile-footer-table-tr">
                        <td className="mobile-footer-table-td">1</td>
                        <td className="mobile-footer-table-td">Hazol</td>
                        <td className="mobile-footer-table-td">412.06x</td>
                        <td className="mobile-footer-table-td">12 LTC</td>
                    </tr>
                    <tr className="mobile-footer-table-tr">
                        <td className="mobile-footer-table-td">2</td>
                        <td className="mobile-footer-table-td">BWimer</td>
                        <td className="mobile-footer-table-td">339.01x</td>
                        <td className="mobile-footer-table-td">895.99 USDT</td>
                    </tr>
                    <tr className="mobile-footer-table-tr">
                        <td className="mobile-footer-table-td">3</td>
                        <td className="mobile-footer-table-td">lipski5</td>
                        <td className="mobile-footer-table-td">313.03x</td>
                        <td className="mobile-footer-table-td">0.006 BTC</td>
                    </tr>
                    <tr className="mobile-footer-table-tr">
                        <td className="mobile-footer-table-td">4</td>
                        <td className="mobile-footer-table-td">John</td>
                        <td className="mobile-footer-table-td">253.63x</td>
                        <td className="mobile-footer-table-td">23.56 LTC</td>
                    </tr>
                    <tr className="mobile-footer-table-tr">
                        <td className="mobile-footer-table-td">5</td>
                        <td className="mobile-footer-table-td">IQ500</td>
                        <td className="mobile-footer-table-td">120.10x</td>
                        <td className="mobile-footer-table-td">-</td>
                    </tr>
                    <tr className="mobile-footer-table-tr">
                        <td className="mobile-footer-table-td">6</td>
                        <td className="mobile-footer-table-td">THECREW</td>
                        <td className="mobile-footer-table-td">26.07x</td>
                        <td className="mobile-footer-table-td">-</td>
                    </tr>
                    <tr className="mobile-footer-table-tr">
                        <td className="mobile-footer-table-td">7</td>
                        <td className="mobile-footer-table-td">fein</td>
                        <td className="mobile-footer-table-td">25.95x</td>
                        <td className="mobile-footer-table-td">53.52 USDT</td>
                    </tr>
                    <tr className="mobile-footer-table-tr">
                        <td className="mobile-footer-table-td">8</td>
                        <td className="mobile-footer-table-td">realscott</td>
                        <td className="mobile-footer-table-td">15.05x</td>
                        <td className="mobile-footer-table-td">6.06 LTC</td>
                    </tr>
                    <tr className="mobile-footer-table-tr">
                        <td className="mobile-footer-table-td">9</td>
                        <td className="mobile-footer-table-td">bzar52</td>
                        <td className="mobile-footer-table-td">14.05x</td>
                        <td className="mobile-footer-table-td">2.65 LTC</td>
                    </tr>
                    <tr className="mobile-footer-table-tr">
                        <td className="mobile-footer-table-td">10</td>
                        <td className="mobile-footer-table-td">Coin99</td>
                        <td className="mobile-footer-table-td">10.50x</td>
                        <td className="mobile-footer-table-td">21 USDT</td>
                    </tr>
                </table>
            </div>
        </section>
    );
};

export default Leaderboard;
