import React, {useState} from 'react';
import {Button, Divider, Form, Input} from "antd";
import "./auth.css";
import {requiredRule} from "../../utils/formRulesValidation";
import Password from "antd/es/input/Password";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {PATH} from "../../router/PATH";
import {loginAction} from "../../redux_ver2/actions/auth.actions";

const LoginForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // login -> field to detect is it email or username
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const handleLoginChange = (e) => setLogin(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    const onFinish = () => dispatch(loginAction(login, password));

    return (
        <>
            <h1 className={"auth-header"}>Welcome Back!</h1>

            <Form
                onFinish={onFinish}
                autoComplete="off"
                style={{padding: "25px 0"}}
            >
                <Form.Item
                    name={"login_data"}
                    rules={[
                        requiredRule("Login is required")
                    ]}
                >
                    <Input
                        placeholder={"Username or Email"}
                        value={login}
                        onChange={handleLoginChange}
                        className='auth-mobile-input-size'
                    />
                </Form.Item>

                <Form.Item
                    name={"password"}
                    rules={[
                        requiredRule("Password is required"),
                    ]}
                >
                    <Password
                        placeholder={"Password"}
                        value={password}
                        onChange={handlePasswordChange}
                        className='auth-mobile-input-size'
                    />
                </Form.Item>

                <Button
                    style={{margin: "15px 0 0 0"}}
                    htmlType={'submit'}
                >Login</Button>
            </Form>

            <Divider style={{border: "1px solid grey"}}/>

            <div className={"auth-footer"}>
                <p style={{marginBottom: "3px"}} className={"auth-footer-text"}>Don't have account yet?</p>
                <div>
                    <span
                        className={"auth-footer-link"}
                        onClick={() => {
                            navigate(PATH.AUTH.SIGN_IN, {
                                state: { background: location?.state?.background }
                            })
                        }}
                    >
                        Register

                    </span>
                    <span style={{margin: "0 5px"}}>|</span>
                    <span
                        style={{textAlign: "center", paddingTop: "15px"}}
                        className={"auth-footer-link"}
                        onClick={() => {
                            navigate(PATH.FORGOT_PASSWORD, {
                                state: {background: location?.state?.background}
                            })
                        }}
                    >
                        Forgot Password?
                    </span>
                </div>
            </div>
        </>
    );
};

export default LoginForm;