import React from 'react';
import ModalPage from "../../pages/ModalPage";
import LuckyWheelContainer from "./LuckyWheelContainer";

const LuckyWheelWrapper = () => {
    return (
        <ModalPage
            component={<LuckyWheelContainer/>}
            title={"Wheely"}
        />
    );
};

export default LuckyWheelWrapper;