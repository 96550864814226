import React, {useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {balancesSelector, subPartnerIdSelector} from "../../redux_ver2/selector/wallet.selector";
import {userDataSelector} from "../../redux_ver2/selector/user.selector";
import {PATH} from "../../router/PATH";
import {useLocation, useNavigate} from "react-router-dom";
import {getTimeZone} from "../../utils/getTimeZone";
import socket from "../../socket";
import $api from "../../api";
import {errorFeedback} from "../../redux_ver2/actions/feedback.actions";
import {requiredRule} from "../../utils/formRulesValidation";
import {depositCrypto} from "../../utils/deposit-crypto";
import {loadingFinish, loadingStart} from "../../redux_ver2/actions/loading.actions";

const WithdrawForm = () => {

    const location = useLocation();
    const dispatch = useDispatch();

    const userData = useSelector(userDataSelector);
    const subPartner = useSelector(subPartnerIdSelector);

    const balances = useSelector(balancesSelector);
    const navigate = useNavigate();

    const [crypto, setCrypto] = useState("");
    const [amount, setAmount] = useState(0);
    const [address, setAddress] = useState("");

    const [renderCrypto, setRenderCrypto] = useState([]);

    useEffect(() => {

        let balanceAndDepositCryptoCombination = balances.map(item => {
            let available = depositCrypto.find(dc => dc.code === item.crypto);
            return {
                ...item,
                ...available
            }
        });

        setRenderCrypto(balanceAndDepositCryptoCombination);

    }, [balances]);

    const handleCryptoChange = c => setCrypto(c);
    const handleAmountChange = e => setAmount(e);
    const handleAddressChange = e => setAddress(e.target.value);


    const onFinish = async () => {
        dispatch(loadingStart());
        const validateAddress = await $api.post("/withdraw-v2/validate", {
            address: address,
            crypto: crypto
        });

        if (!validateAddress.data.validation) {
            dispatch(errorFeedback(validateAddress.data));
            dispatch(loadingFinish());
        } else {
            socket.emit("bettor:withdraw", {
                crypto: crypto,
                timeZone: getTimeZone(),
                amount: amount,
                address: address,
                email: userData?.email,
                userId: userData?.id,
                subPartnerId: subPartner,
                username: userData?.username
            });
            dispatch(loadingFinish());
        }
    }


    return (
        <div>
            <Form
                onFinish={onFinish}
                style={{padding: "10px 0"}}
            >
                <Form.Item
                    style={{width: "100%"}}
                    name={"Crypto"}
                    rules={[
                        requiredRule("Crypto is required")
                    ]}
                >
                    <Select
                        className={"select-crypto"}
                        dropdownStyle={{background: "#181928", zIndex: 10000000}}
                        style={{color: "#fff"}}

                        optionFilterProp="children"

                        onChange={handleCryptoChange}
                        placeholder="Select Crypto"
                        value={crypto}

                        options={
                            renderCrypto?.map((item) => {
                                return {
                                    value: item?.code,
                                    label: (
                                        (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={item?.icon} alt={item.label} style={{ marginRight: 8, width: '24px', height: '24px' }} />
                                                {item.label}. Available: {item.amount}
                                            </div>
                                        )
                                    )
                                }
                            })
                        }
                    />
                </Form.Item>

                <Form.Item
                    name={"amount"}
                    rules={[
                        requiredRule("Amount is required")
                    ]}
                >
                    <InputNumber
                        style={{width: "100%", fontSize: "16px"}}
                        size={"large"}
                        value={amount}
                        onChange={handleAmountChange}
                        placeholder={"Enter crypto amount"}
                    />
                </Form.Item>


                <Form.Item
                    name={"address"}
                    rules={[
                        requiredRule("Address is required")
                    ]}
                >
                    <Input
                        style={{width: "100%", fontSize: "17px"}}
                        size={"large"}
                        value={address}
                        onChange={handleAddressChange}
                        placeholder={"Enter wallet address"}
                    />
                </Form.Item>

                <Button htmlType={"submit"} type={"primary"}>Withdraw</Button>
            </Form>

            <p
                className={"auth-footer-link"}
                style={{position: "relative", bottom: 0, left: 0, textAlign: "center"}}
                onClick={() => {
                    navigate(PATH.DROPDOWN.TRANSACTIONS.WITHDRAWS, {
                        state: {background: location?.state?.background}
                    })
                }}
            >Check status of your transactions here</p>


        </div>
    );
};

export default WithdrawForm;
