import React from 'react';
import ModalPage from "../../pages/ModalPage";
import Deposit from "./Deposit";

const DepositWrapper = () => {
    return (
        <ModalPage
            component={<Deposit/>}
            title={"Wallet"}
        />
    );
};

export default DepositWrapper;