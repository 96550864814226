import React from 'react';
import ModalPage from "../../pages/ModalPage";
import Challenges from "./Challenges";

const ChallengesWrapper = () => {
    return (
        <ModalPage
            component={<Challenges/>}
            title={"Challenges"}
        />
    );
};

export default ChallengesWrapper;